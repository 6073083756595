import * as dayjs from 'dayjs';

export class DateHelper {
    /**
     * - return null if dateString === null
     * - this is a way around the fact that new Date(null) gives 1970-01-01T00:00:00.000Z
     */
    public static convertStringToDate(dateString: string): Date | null {
        if (!dateString) {
            return null;
        }

        const date = dateString.split(' ');
        if (date.length === 1) {
            return new Date(dateString);
        }

        if (date.length === 2) {
            return new Date(date[0] + 'T' + date[1]);
        }

        return null;
    }

    public static convertDateToHttpPlayload(date: Date | string | null, isDateTime: boolean = false): string | null {
        if (date) {
            if (isDateTime) {
                return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
            }
            return dayjs(date).format('YYYY-MM-DD');
        } else {
            return null;
        }
    }

    public static convertDateToHttpPlayloadTime(date: Date | string | null): string | null {
        if (date) {
            return dayjs(date).format('HH:mm:ss');
        } else {
            return null;
        }
    }
}
