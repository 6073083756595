import { Action, createReducer, on } from '@ngrx/store';
import { Agency } from 'src/app/models/agency.model';
import { OrderBalancePaginated, OrderBalanceSummary } from 'src/app/models/order-balance.model';
import { Period } from 'src/app/models/period.model';
import { TransferDetail } from 'src/app/models/transfer-detail.model';
import { IOrderBalanceItemSummary } from 'src/app/models/order-summary.model';
import * as OrderActions from './order-balance.actions';
import { OrderBalanceState } from './order-balance.selectors';
import { IOrderBalancePaginatedItem } from 'src/app/models/order-balance-paginated.model';
import { Partner } from 'src/app/models/partner.model';
import { IPartnerOrderBalancePaginatedItem, IPartnerOrderSummary } from 'src/app/models/partner-order-balance.model';
import {
    IFeeReceiptOrderBalancePaginatedItem,
    IFeeReceiptOrderSummary,
} from 'src/app/models/fee-receipt-order-balance.model';
import { PnPartner } from 'src/app/models/pn-partner.model';
import { PnPeriod } from 'src/app/models/pn-period.model';
import { PnOrderBalancePaginated, PnOrderBalanceSummary } from 'src/app/models/pn-order-balance.model';
import { FeeReceiptPaginatedItem } from 'src/app/models/fee-receipt-paginated.model';
import { FeeReceiptSummary } from 'src/app/models/fee-receipt-summary.model';

export interface OrderBalanceState {
    orderBalanceSummary: OrderBalanceSummary | null;
    orderBalancePaginated: OrderBalancePaginated | null;
    agencies: Agency[] | null;
    partners: Partner[] | null;
    feeReceiptPartners: Partner[] | null;
    periods: Period[] | null;
    partnerPeriods: Period[] | null;
    orderBalanceItemSummary: IOrderBalanceItemSummary | null;
    orderBalanceItems: IOrderBalancePaginatedItem[] | null;
    orderBalanceItemsTotal: number | null;
    partnerOrderBalanceSummary: IPartnerOrderSummary | null;
    partnerOrderBalanceItems: IPartnerOrderBalancePaginatedItem[] | null;
    partnerOrderBalanceItemsTotal: number | null;
    feeReceiptOrderBalanceSummary: IFeeReceiptOrderSummary | null;
    feeReceiptOrderBalanceItems: IFeeReceiptOrderBalancePaginatedItem[] | null;
    feeReceiptOrderBalanceItemsTotal: number | null;
    feeReceiptSummary: FeeReceiptSummary | null;
    feeReceiptItems: FeeReceiptPaginatedItem[] | null;
    totalFeeReceiptItems: number | null;
    feeReceiptDoc: string | null;
    confirmFeeReceipt: boolean | null;
    pnPeriods: PnPeriod[] | null;
    pnPartners: PnPartner[] | null;
    pnOrderBalanceSummary: PnOrderBalanceSummary | null;
    pnOrderBalancePaginated: PnOrderBalancePaginated | null;
    error: any;
}

export const initialState: OrderBalanceState = {
    orderBalanceSummary: null,
    orderBalancePaginated: null,
    agencies: null,
    partners: null,
    feeReceiptPartners: null,
    periods: null,
    partnerPeriods: null,
    orderBalanceItemSummary: null,
    orderBalanceItems: null,
    orderBalanceItemsTotal: null,
    partnerOrderBalanceSummary: null,
    partnerOrderBalanceItems: null,
    partnerOrderBalanceItemsTotal: null,
    feeReceiptOrderBalanceSummary: null,
    feeReceiptOrderBalanceItems: null,
    feeReceiptOrderBalanceItemsTotal: null,
    feeReceiptSummary: null,
    feeReceiptItems: null,
    totalFeeReceiptItems: null,
    feeReceiptDoc: null,
    confirmFeeReceipt: null,
    pnPeriods: null,
    pnPartners: null,
    pnOrderBalanceSummary: null,
    pnOrderBalancePaginated: null,
    error: null,
};

export const orderBalanceReducer = createReducer(
    initialState,
    on(OrderActions.getAgencies, (state, action) => ({ ...state })),
    on(OrderActions.getAgenciesSuccess, (state, action) => ({ ...state, agencies: action.data })),
    on(OrderActions.getAgenciesFail, (state, action) => ({ ...state, agencies: action.error })),

    on(OrderActions.getPeriods, (state, action) => ({ ...state })),
    on(OrderActions.getPeriodsSuccess, (state, action) => ({ ...state, periods: action.data })),
    on(OrderActions.getPeriodsFail, (state, action) => ({ ...state, periods: action.error })),

    on(OrderActions.getOrderBalanceSummarySuccess, (state, action) => ({
        ...state,
        orderBalanceSummary: action.data,
    })),
    on(OrderActions.getOrderBalanceSummaryFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getOrderBalancePaginatedSuccess, (state, action) => {
        const concatedData = action.nextPage
            ? [...state.orderBalancePaginated?.result!, ...action.data.result!]
            : action.data.result;
        action.data.result = concatedData;
        return {
            ...state,
            orderBalancePaginated: action.data,
        };
    }),
    on(OrderActions.getOrderBalancePaginatedFail, (state, action) => ({
        ...state,
        error: action.error,
    })),

    on(OrderActions.putTransferDetailFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getOrderBalanceItemSummarySuccess, (state, action) => ({
        ...state,
        orderBalanceItemSummary: action.orderBalanceItemSummary,
    })),
    on(OrderActions.getOrderBalanceItemSummaryFail, (state, action) => ({ ...state, agencies: action.error })),

    on(OrderActions.getOrderPaginatedSuccess, (state, action) => {
        let items = action.orderBalancePaginated.result;
        if (action.nextPage && state.orderBalanceItems?.length) {
            items = items?.concat(state.orderBalanceItems) || null;
        }
        return {
            ...state,
            orderBalanceItems: items,
            orderBalanceItemsTotal: action.orderBalancePaginated.total,
        };
    }),
    on(OrderActions.getOrderBalanceItemSummaryFail, (state, action) => ({ ...state, agencies: action.error })),

    //#region Partner Order Balance
    on(OrderActions.getPartners, (state, action) => ({ ...state })),
    on(OrderActions.getPartnersSuccess, (state, action) => ({ ...state, partners: action.partners })),
    on(OrderActions.getPartnersFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getPartnerPeriods, (state, action) => ({ ...state })),
    on(OrderActions.getPartnerPeriodsSuccess, (state, action) => ({ ...state, partnerPeriods: action.data })),
    on(OrderActions.getPartnerPeriodsFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getPartnerOrderSummary, (state, action) => ({ ...state })),
    on(OrderActions.getPartnerOrderSummarySuccess, (state, action) => {
        return {
            ...state,
            partnerOrderBalanceSummary: action.partnerOrderSummary,
        };
    }),
    on(OrderActions.getPartnerOrderSummaryFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getPartnerOrderPaginated, (state, action) => ({ ...state })),
    on(OrderActions.getPartnerOrderPaginatedSuccess, (state, action) => {
        let items = action.partnerOrderBalancePaginated.result;
        if (action.nextPage && state.partnerOrderBalanceItems?.length) {
            items = state.partnerOrderBalanceItems.concat(items || []);
        }
        return {
            ...state,
            partnerOrderBalanceItems: items,
            partnerOrderBalanceItemsTotal: action.partnerOrderBalancePaginated.total,
        };
    }),
    on(OrderActions.getPartnerOrderPaginatedFail, (state, action) => ({ ...state, error: action.error })),
    //#endregion

    //#region Fee Receipt Order Balance
    on(OrderActions.getFeeReceiptPartners, (state, action) => ({ ...state })),
    on(OrderActions.getFeeReceiptPartnersSuccess, (state, action) => ({
        ...state,
        feeReceiptPartners: action.partners,
    })),
    on(OrderActions.getFeeReceiptPartnersFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getFeeReceiptOrderSummary, (state, action) => ({ ...state })),
    on(OrderActions.getFeeReceiptOrderSummarySuccess, (state, action) => {
        return {
            ...state,
            feeReceiptOrderBalanceSummary: action.feeReceiptOrderSummary,
        };
    }),
    on(OrderActions.getFeeReceiptOrderSummaryFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getFeeReceiptOrderPaginated, (state, action) => ({ ...state })),
    on(OrderActions.getFeeReceiptOrderPaginatedSuccess, (state, action) => {
        let items = action.feeReceiptOrderBalancePaginated.result;
        if (action.nextPage && state.feeReceiptOrderBalanceItems?.length) {
            items = items?.concat(state.feeReceiptOrderBalanceItems) || null;
        }
        return {
            ...state,
            feeReceiptOrderBalanceItems: items,
            feeReceiptOrderBalanceItemsTotal: action.feeReceiptOrderBalancePaginated.total,
        };
    }),
    on(OrderActions.getFeeReceiptOrderPaginatedFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getFeeReceiptSummary, (state, action) => ({ ...state })),
    on(OrderActions.getFeeReceiptSummarySuccess, (state, action) => {
        return {
            ...state,
            feeReceiptSummary: action.result,
        };
    }),
    on(OrderActions.getFeeReceiptSummaryFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getFeeReceiptPaginated, (state, action) => ({ ...state })),
    on(OrderActions.getFeeReceiptPaginatedSuccess, (state, action) => {
        let items = action.result.result;
        if (action.nextPage && state.feeReceiptItems?.length) {
            items = items?.concat(state.feeReceiptItems) || null;
        }
        return {
            ...state,
            feeReceiptItems: items,
            totalFeeReceiptItems: action.result.total,
        };
    }),
    on(OrderActions.getFeeReceiptPaginatedFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getFeeReceiptDoc, (state, action) => ({ ...state })),
    on(OrderActions.getFeeReceiptDocSuccess, (state, action) => {
        return {
            ...state,
            feeReceiptDoc: action.result,
        };
    }),
    on(OrderActions.getFeeReceiptDocFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.postConfirmFeeReceipt, (state, action) => ({ ...state })),
    on(OrderActions.postConfirmFeeReceiptSuccess, (state, action) => {
        return {
            ...state,
            confirmFeeReceipt: action.result,
        };
    }),
    on(OrderActions.postConfirmFeeReceiptFail, (state, action) => ({ ...state, error: action.error })),

    //#endregion
    on(OrderActions.getPnPartnersSuccess, (state, action) => ({ ...state, pnPartners: action.data })),
    on(OrderActions.getPnPartnersFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getPnPeriodsSuccess, (state, action) => ({ ...state, pnPeriods: action.data })),
    on(OrderActions.getPnPeriodsFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getPnOrderBalanceSummarySuccess, (state, action) => ({
        ...state,
        pnOrderBalanceSummary: action.data,
    })),
    on(OrderActions.getPnOrderBalanceSummaryFail, (state, action) => ({ ...state, error: action.error })),

    on(OrderActions.getPnOrderBalancePaginatedSuccess, (state, action) => {
        const concatedData = action.nextPage
            ? [...state.pnOrderBalancePaginated?.result!, ...action.data.result!]
            : action.data.result;
        action.data.result = concatedData;
        return {
            ...state,
            pnOrderBalancePaginated: action.data,
        };
    }),
    on(OrderActions.getPnOrderBalancePaginatedFail, (state, action) => ({
        ...state,
        error: action.error,
    })),

    on(OrderActions.putPnTransferDetailFail, (state, action) => ({ ...state, error: action.error }))
);

export function reducer(state: OrderBalanceState | undefined, action: Action) {
    return orderBalanceReducer(state, action);
}
