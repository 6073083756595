import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ENDPOINT } from '../constants/endpoint.constant';

import { HttpService } from './base/http.service';
import { IApiResponse } from '../models/interfaces/api-response.interface';
import { IAgencyMemberFilter } from '../modules/tw-booking/interfaces/filters/agency-member-filter.interface';
import { IAgencyMember } from '../models/tw-booking-model/tw-booking-interface/agency-member.interface';

@Injectable({
    providedIn: 'root',
})
export class AgencyService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    getAgencies(): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.AGENCY.GET_ALL;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options);
            })
        );
    }

    getAgencyMembers(filters: IAgencyMemberFilter): Observable<any> {
        let params = new HttpParams();
        if (filters) {
            params = params.append('filters', JSON.stringify(filters));
        }

        const url = `${environment.apiUrl}agency_members?${params.toString()}`;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options);
            })
        );
    }
}
