import { Action, createReducer, on } from '@ngrx/store';
import * as BookingActions from './booking.actions';
import { IBookingConfig } from 'src/app/modules/booking/services/booking.service';

export interface BookingState {
    bookingConfigs: IBookingConfig[];
    error: any;
}

export const initialState: BookingState = {
    bookingConfigs: [],
    error: null,
};

export const bookingReducer = createReducer(
    initialState,
    on(BookingActions.getBookingConfigs, (state, action) => ({ ...state })),
    on(BookingActions.getBookingConfigsSuccess, (state, action) => ({ ...state, bookingConfigs: action.data })),
    on(BookingActions.getBookingConfigsFailure, (state, action) => ({ ...state, error: action.error }))
);

export function reducer(state: BookingState | undefined, action: Action) {
    return bookingReducer(state, action);
}
