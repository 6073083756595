import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Partner } from 'src/app/models/partner.model';
import { PartnerService } from 'src/app/services/partner.service';
import * as PartnerActions from './partner.actions';

@Injectable()
export class PartnerEffects {
    constructor(
        private actions$: Actions,
        private ngxUiLoaderService: NgxUiLoaderService,
        private partnerService: PartnerService
    ) {}

    public getPartners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PartnerActions.getPartners),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.partnerService.getPartners().pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return PartnerActions.getPartnersSuccess({
                                data: resp.data.map((d: any) => new Partner().deserialize(d)),
                            });
                        } else {
                            return PartnerActions.getPartnersFailure({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            PartnerActions.getPartnersFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );
}
