import { IDeserializable } from './deserializable.model';

export class PartnerOrderBalanceFilterBase {
    periodId!: number;
    partnerId!: number | null;
}

//#region  Partner Order Balance Summary

export class PartnerOrderBalanceSummaryFilter extends PartnerOrderBalanceFilterBase {}

export class PartnerOrderSummary implements IPartnerOrderSummary {
    public sumPrice!: number;
    public orderBalance!: IOrderBalance;

    public deserialize(resp: any): PartnerOrderSummary {
        this.sumPrice = resp.sum_price;
        if (resp.order_balance) {
            this.orderBalance = new OrderBalance().deserialize(resp.order_balance);
        }
        return this;
    }
}

export interface IPartnerOrderSummary {
    sumPrice: number | null;
    orderBalance: IOrderBalance | null;
}

export class OrderBalance implements IOrderBalance {
    public id!: number;
    public sumNetAmount!: number;
    public transfered!: boolean;

    public deserialize(resp: any) {
        this.id = resp.id;
        this.sumNetAmount = resp.sum_net_amount;
        this.transfered = resp.transfered;

        return this;
    }
}

export interface IOrderBalance {
    id: number | null;
    sumNetAmount: number | null;
    transfered: boolean | null;
}

//#endregion

//#region  Partner Order Balance Paginated
export class PartnerOrderBalancePaginatedCriteria {
    filters!: PartnerOrderBalancePaginatedFilter;
    page!: number;
    pageEntries!: number;
}

export class PartnerOrderBalancePaginatedFilter extends PartnerOrderBalanceFilterBase {}

export class PartnerOrderBalancePaginated implements IDeserializable, IPartnerOrderBalancePaginated {
    page!: number | null;
    pageEntries!: number | null;
    total!: number | null;
    result!: IPartnerOrderBalancePaginatedItem[] | null;
    deserialize(source: any): this {
        this.page = source.page;
        this.pageEntries = source.page_entries;
        this.total = source.total;
        this.result = source.result.map((r: any) => new PartnerOrderBalancePaginatedItem().deserialize(r));
        return this;
    }
}
export interface IPartnerOrderBalancePaginated {
    page: number | null;
    pageEntries: number | null;
    total: number | null;
    result: IPartnerOrderBalancePaginatedItem[] | null;
}

export interface IPartnerOrderBalancePaginatedItem {
    id: number | null;
    itemQuantity: number | null;
    pricePerTicket: number | null;
    commissionPerTicket: number | null;
    netAmountPerTicket: number | null;
    netAmount: number | null;
    order: Order | null;
    voucher: Voucher | null;
}

export class PartnerOrderBalancePaginatedItem implements IDeserializable, IPartnerOrderBalancePaginatedItem {
    id!: number;
    itemQuantity!: number;
    pricePerTicket!: number;
    commissionPerTicket!: number;
    netAmountPerTicket!: number;
    netAmount!: number;
    order!: Order;
    voucher!: Voucher;

    deserialize(source: any): this {
        this.id = source.id;
        this.itemQuantity = source.item_quantity;
        this.pricePerTicket = source.price_per_ticket;
        this.commissionPerTicket = source.commission_per_ticket;
        this.netAmountPerTicket = source.net_amount_per_ticket;
        this.netAmount = source.net_amount;
        this.order = new Order().deserialize(source.order);
        this.voucher = new Voucher().deserialize(source.voucher);

        return this;
    }
}

export class Order implements IDeserializable {
    id!: number;
    orderCode!: string;
    contactName!: string;
    phoneNumber!: string;
    paidAt!: Date;
    voucherTicketQuantity!: VoucherTicketQuantity;

    deserialize(source: any): this {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.contactName = source.contact_name;
        this.phoneNumber = source.phone_number;
        this.paidAt = source.paid_at;
        this.voucherTicketQuantity = new VoucherTicketQuantity().deserialize(source.voucher_ticket_quantity);
        return this;
    }
}
export class VoucherTicketQuantity implements IDeserializable {
    all!: number;
    used!: number;
    unused!: number;
    deserialize(source: any): this {
        this.all = source.all;
        this.used = source.used;
        this.unused = source.unused;
        return this;
    }
}
export class Voucher implements IDeserializable {
    id!: number;
    name!: string;
    deserialize(source: any): this {
        this.id = source.id;
        this.name = source.name;
        return this;
    }
}
//#endregion
