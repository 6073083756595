import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ENDPOINT } from '../constants/endpoint.constant';
import { HttpService } from './base/http.service';

@Injectable({
    providedIn: 'root',
})
export class PartnerService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getPartners(): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.PARTNER.GET_ALL;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options);
            })
        );
    }
}
