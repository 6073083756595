import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ENDPOINT } from '../constants/endpoint.constant';
import { OrderPaginatedCriteria, OrderPaginatedFilter } from '../models/order.model';
import { HttpService } from './base/http.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getOrderPaginated(criteria: OrderPaginatedCriteria): Observable<any> {
        let params = new HttpParams();
        params = params.append('filters', this.serializedOrderPaginatedFilter(criteria.filters));

        if (criteria?.page) {
            params = params.append('page', criteria?.page);
        }
        if (criteria?.pageEntries) {
            params = params.append('page_entries', criteria?.pageEntries);
        }

        const url = environment.apiUrl + ENDPOINT.PRODUCT.ORDER.GET_PAGINATED;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getOrderExcelReport(filters: OrderPaginatedFilter): Observable<any> {
        let params = new HttpParams();
        params = params.append('filters', this.serializedOrderPaginatedFilter(filters));

        const url = environment.apiUrl + ENDPOINT.PRODUCT.ORDER.GET_EXCEL_REPORT;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    private serializedOrderPaginatedFilter(filter: OrderPaginatedFilter | null): string {
        if (!filter) return JSON.stringify({});
        let result = {
            search_string: filter.searchString,
            agency_id: filter.agencyId,
            supplier_id: filter.supplierId,
            order_created_at_between: {
                min_date: filter.orderCreateMinDate,
                max_date: filter.orderCreateMaxDate,
            },
        };
        return JSON.stringify(result);
    }
}
