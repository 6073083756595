import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ENDPOINT } from '../constants/endpoint.constant';
import { IResponse } from '../models/interfaces/response.interface';
import { ISupplierConfig } from '../models/tw-booking-model/tw-booking-interface/supplier-config.interface';
import { ISupplier } from '../models/tw-booking-model/tw-booking-interface/supplier.interface';
import { ISupplierConfigFilter } from '../modules/order/interfaces/supplier-config-filter.interface';
import { HttpService } from './base/http.service';
import { ISupplierInfo } from '../models/tw-booking-model/tw-booking-interface/supplier-info.interface';

@Injectable({
    providedIn: 'root',
})
export class SupplierService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    getSupplierInfo(id: number): Observable<ISupplierInfo | null> {
        const url = `${environment.apiUrl}suppliers/${id}`;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get<IResponse<ISupplierInfo>>(url, options).pipe(
                    map((response: IResponse<ISupplierInfo>) => {
                        if (response.status === 'success') {
                            return response.data;
                        }

                        return null;
                    })
                );
            })
        );
    }

    getSuppliers(): Observable<ISupplier[]> {
        const url = environment.apiUrl + ENDPOINT.SUPPLIER.GET_ALL;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get<IResponse<ISupplier[]>>(url, options).pipe(
                    map((response: IResponse<ISupplier[]>) => {
                        if (response.status === 'success') {
                            return response.data;
                        }

                        return [];
                    })
                );
            })
        );
    }

    getSupplierConfig(filters: ISupplierConfigFilter): Observable<ISupplierConfig | null> {
        const url = environment.apiUrl + ENDPOINT.SUPPLIER_CONFIG.GET_ONE;

        let params = new HttpParams();
        params = params.set('filters', JSON.stringify(filters));

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get<IResponse<ISupplierConfig>>(url, options).pipe(
                    map((response: IResponse<ISupplierConfig>) => {
                        if (response.status === 'success') {
                            return response.data;
                        }

                        return null;
                    })
                );
            })
        );
    }
}
