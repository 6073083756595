import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { retry, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    public localStorageTokenKey = 'token';

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {}

    // get
    public get(url: string, hasAuthorization: boolean = true): Observable<any> {
        return new Observable((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .get(url, headers)
                    .pipe(timeout(30000), retry(5))
                    .subscribe(
                        (result: any) => {
                            if (result.status === 'error') {
                                this.redirectToErrorPage(observer, result);
                            } else {
                                observer.next(result);
                                observer.complete();
                            }
                        },
                        (response) => observer.next(response.error)
                    );
            }, this.onCannotCreateRequestHeader);
        });
    }

    // post
    public post(url: string, body: any, hasAuthorization: boolean = true): Observable<any> {
        return new Observable((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .post(url, body, headers)
                    .pipe(retry(5))
                    .subscribe(
                        (result: any) => {
                            if (result.status === 'error') {
                                this.redirectToErrorPage(observer, result);
                            } else {
                                observer.next(result);
                            }
                        },
                        (response) => observer.next(response.error)
                    );
            }, this.onCannotCreateRequestHeader);
        });
    }

    // put
    public put(url: string, body: any, hasAuthorization: boolean = true): Observable<any> {
        return new Observable((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .put(url, body, headers)
                    .pipe(retry(5))
                    .subscribe(
                        (result: any) => {
                            if (result.status === 'error') {
                                this.redirectToErrorPage(observer, result);
                            } else {
                                observer.next(result);
                            }
                        },
                        (response) => observer.next(response.error)
                    );
            }, this.onCannotCreateRequestHeader);
        });
    }

    // delete
    public delete(url: string, hasAuthorization: boolean = true, body?: any): Observable<any> {
        return new Observable((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .delete(url, { ...headers, body })
                    .pipe(retry(5))
                    .subscribe(
                        (result: any) => {
                            if (result.status === 'error') {
                                this.redirectToErrorPage(observer, result);
                            } else {
                                observer.next(result);
                            }
                        },
                        (response) => observer.next(response.error)
                    );
            }, this.onCannotCreateRequestHeader);
        });
    }

    private redirectToErrorPage(observer: any, result: any) {
        switch (result.error.code) {
            case 401:
                this.router.navigate(['401']);
                break;
            case 404:
                this.router.navigate(['404']);
                break;
            case 500:
                this.router.navigate(['505']);
                break;
            default:
                observer.next(result);
        }
    }

    // custom request
    public request(request: Request, headers: any): Observable<any> {
        headers.responseType = 'json';
        return this.http.request(request.method.toString(), request.url, headers);
    }

    public getRequestOptions(hasAuthorization: boolean): Observable<any> {
        return new Observable((observer) => {
            const httpHeaders = new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            });

            const requestOptions = {
                headers: httpHeaders,
                responseType: 'json',
            };

            if (!hasAuthorization) {
                observer.next(requestOptions);
            } else {
                requestOptions.headers = requestOptions.headers.set('Authorization', this.getToken());
                observer.next(requestOptions);
            }
        });
    }

    private onCannotCreateRequestHeader(reason: any): Observable<any> {
        alert('something went wrong');
        return Observable.throw(reason);
    }

    public getToken(): string {
        let token: string | null;
        token = localStorage.getItem(this.localStorageTokenKey);

        if (token) {
            return token;
        }

        return '';
    }

    public getQueryString(obj: any): string {
        const params = new URLSearchParams();
        for (const key in obj) {
            let param = obj[key];
            if (typeof param === 'object') {
                param = JSON.stringify(param);
            }
            params.set(key, param);
        }
        return params.toString();
    }
}
