import { IDeserializable } from './deserializable.model';
import { FeeReceiptFilterBase } from './fee-receipt-paginated.model';

export class FeeReceiptSummaryFilter extends FeeReceiptFilterBase {}

export class FeeReceiptSummary implements IDeserializable {
    quantity!: FeeReceiptQuantity;
    sumNetCommissionFee!: number;

    deserialize(source: any): this {
        this.quantity = new FeeReceiptQuantity().deserialize(source.fee_receipt_quantity);
        this.sumNetCommissionFee = source.sum_net_commission_fee;
        return this;
    }
}

export class FeeReceiptQuantity implements IDeserializable {
    all!: number;
    confirmed!: number;
    notConfirmed!: number;

    transferCompleted!: boolean;
    transferIncompleted!: boolean;
    waitingForTransfer!: boolean;

    deserialize(source: any): this {
        if (source) {
            this.all = source.all;
            this.confirmed = source.confirmed;
            this.notConfirmed = source.not_confirmed;

            if (this.all === this.confirmed) {
                this.transferCompleted = true;
            } else if (this.confirmed === 0) {
                this.waitingForTransfer = true;
            } else {
                this.transferIncompleted = true;
            }
        } else {
            this.waitingForTransfer = true;
        }

        return this;
    }
}
