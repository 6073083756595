import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Payment, PaymentPaginated, PaymentQuantity } from 'src/app/models/campaign.model';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import { CampaignService } from 'src/app/services/campaign.service';
import * as CampaignActions from './campaign.actions';

@Injectable()
export class CampaignEffects {
    constructor(
        private actions$: Actions,
        private ngxUiLoaderService: NgxUiLoaderService,
        private campaignService: CampaignService,
        private bsModalService: BsModalService
    ) {}

    public getPayments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CampaignActions.getPayments),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.campaignService.getPayments(action.filter).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return CampaignActions.getPaymentsSuccess({
                                data: resp.data.map((x: any) => new Payment().deserialize(x)),
                            });
                        } else {
                            return CampaignActions.getPaymentsFailure({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            CampaignActions.getPaymentsFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getPaymentQuantity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CampaignActions.getPaymentQuantity),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.campaignService.getPaymentQuantity(action.filter).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return CampaignActions.getPaymentQuantitySuccess({
                                data: new PaymentQuantity().deserialize(resp.data),
                            });
                        } else {
                            return CampaignActions.getPaymentQuantityFailure({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            CampaignActions.getPaymentQuantityFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getPaymentPaginated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CampaignActions.getPaymentPaginated),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.campaignService.getPaymentPaginated(action.criteria).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return CampaignActions.getPaymentPaginatedSuccess({
                                data: new PaymentPaginated().deserialize(resp.data),
                                nextPage: action.nextPage,
                            });
                        } else {
                            return CampaignActions.getPaymentPaginatedFailure({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            CampaignActions.getPaymentPaginatedFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public cancelOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CampaignActions.cancelOrder),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.campaignService.postCancelOrder(action.orderId, action.cancelReason).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return CampaignActions.cancelOrderSuccess();
                        } else {
                            return CampaignActions.cancelOrderFailure();
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(CampaignActions.cancelOrderFailure());
                    })
                );
            })
        )
    );

    private createOrUpdateCampaignSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CampaignActions.cancelOrderSuccess),
                tap((action) => {
                    let messageString!: string;
                    if (action.type === '[Campaign] Cancel order success.') {
                        messageString = 'ยกเลิก Order เรียบร้อย';
                    }

                    this.bsModalService.show(SuccessModalComponent, {
                        class: 'modal-successful modal-dialog-centered',
                        initialState: {
                            message: messageString,
                        },
                    });
                })
            ),
        { dispatch: false }
    );
}
