import { createAction, props } from '@ngrx/store';
import {
    CampaignPaymentFilter,
    CampaignPaymentPaginatedCriteria,
    Payment,
    PaymentPaginated,
} from 'src/app/models/campaign.model';

export const getPayments = createAction('[Campaign] Get Payments', props<{ filter: CampaignPaymentFilter }>());

export const getPaymentsSuccess = createAction('[Campaign] Get Payments Success', props<{ data: Payment[] }>());

export const getPaymentsFailure = createAction('[Campaign] Get Payments Failure', props<{ error: any }>());

export const getPaymentQuantity = createAction(
    '[Campaign] Get Payment Quantity',
    props<{ filter: CampaignPaymentFilter }>()
);

export const getPaymentQuantitySuccess = createAction(
    '[Campaign] Get Payment Quantity Success',
    props<{ data: any }>()
);

export const getPaymentQuantityFailure = createAction(
    '[Campaign] Get Payment Quantity Failure',
    props<{ error: any }>()
);

export const getPaymentPaginated = createAction(
    '[Campaign] Get Payment Paginated',
    props<{ criteria: CampaignPaymentPaginatedCriteria; nextPage: boolean }>()
);

export const getPaymentPaginatedSuccess = createAction(
    '[Campaign] Get Payment Paginated Success',
    props<{ data: PaymentPaginated; nextPage: boolean }>()
);

export const getPaymentPaginatedFailure = createAction(
    '[Campaign] Get Payment Paginated Failure',
    props<{ error: any }>()
);

export const cancelOrder = createAction(
    '[Campaign] Cancel order.',
    props<{ orderId: number; cancelReason?: string }>()
);
export const cancelOrderSuccess = createAction('[Campaign] Cancel order success.');
export const cancelOrderFailure = createAction('[Campaign] Cancel order failure.');
