import { IDeserializable } from './deserializable.model';

export class TransferDetailPayload {
    transferedAt!: string;
    transferedAmount!: number;
    transferDocBase64!: string;
    isSameFile!: boolean;
}

export class TransferDetail implements IDeserializable {
    id!: number;
    transfered!: boolean;
    transferedAt!: Date;
    transferedAmount!: number;
    transferDocFileUrl!: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.transfered = source.transfered;
        this.transferedAt = source.transfered_at;
        this.transferedAmount = source.transfered_amount;
        this.transferDocFileUrl = source.transfer_doc_file_url;

        return this;
    }
}
