import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-success-modal',
    templateUrl: './success-modal.component.html',
    styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent implements OnInit, OnDestroy {
    private doNotRefresh!: boolean;
    public message: string | null = 'บันทึกข้อมูลเรียบร้อยแล้ว';
    public description!: {
        text: string;
        linkText?: string;
        linkUrl?: string;
    };
    private reason!: string;

    @Output() successModalResponse: EventEmitter<boolean> = new EventEmitter();

    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService, private router: Router) {}

    ngOnInit() {
        this.closeOnRouteChange();
    }

    private closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }

    onSubmit() {
        this.successModalResponse.emit(true);
        this.bsModalRef.hide();
    }

    ngOnDestroy() {
        if (!this.doNotRefresh) {
            this.bsModalService.setDismissReason('refresh');
        }
        if (this.reason) {
            this.bsModalService.setDismissReason(this.reason);
        }
    }
}
