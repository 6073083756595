import { IDeserializable } from './deserializable.model';
import * as dayjs from 'dayjs-es';
import { IBasePeriod } from './base.model';

export class Period implements IDeserializable, IPeriod {
    id!: number;
    orderFirstDate!: Date;
    orderLastDate!: Date;
    transferDueDate!: Date;

    year!: number;

    deserialize(source: any): this {
        this.id = source.id;
        this.orderFirstDate = new Date(source.order_first_date);
        this.orderLastDate = new Date(source.order_last_date);
        this.transferDueDate = new Date(source.transfer_due_date);

        this.year = dayjs(this.transferDueDate).year();

        return this;
    }

    static toYearlyPeriods(periods: Period[] | null): Map<number, Period[]> {
        let views = new Map<number, Period[]>();
        views = periods!.reduce(function (d: any, v) {
            d[v.year] = d[v.year] || [];
            d[v.year].push(v);
            return d;
        }, Object.create({}));
        return views;
    }
}

export interface IPeriod extends IBasePeriod {
    id: number | null;
    orderFirstDate: Date | null;
    orderLastDate: Date | null;
    transferDueDate: Date | null;
}
