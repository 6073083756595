import { IDeserializable } from './deserializable.model';

export abstract class PaginatedBase<T extends IDeserializable> implements IDeserializable {
    page!: number;
    pageEntries!: number;
    total!: number;
    result!: T[] | null;
    abstract populateResult(source: any): T[];

    deserialize(source: any): this {
        this.page = source.page;
        this.pageEntries = source.page_entries;
        this.total = source.total;
        this.result = this.populateResult(source);

        return this;
    }
}
