import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ENDPOINT } from '../constants/endpoint.constant';
import { CampaignPaymentFilter, CampaignPaymentPaginatedCriteria } from '../models/campaign.model';
import { HttpService } from './base/http.service';

@Injectable({
    providedIn: 'root',
})
export class CampaignService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getPayments(filters: CampaignPaymentFilter): Observable<any> {
        let params = new HttpParams();
        params = params.append('filters', this.serializedCampaignPaymentFilter(filters));

        const url = environment.apiUrl + ENDPOINT.CAMPAIGN.PAYMENT.GET;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPaymentQuantity(filters: CampaignPaymentFilter): Observable<any> {
        let params = new HttpParams();

        params = params.append('filters', this.serializedCampaignPaymentFilter(filters));

        const url = environment.apiUrl + ENDPOINT.CAMPAIGN.PAYMENT.GET_COUNT;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPaymentPaginated(criteria: CampaignPaymentPaginatedCriteria): Observable<any> {
        let params = new HttpParams();
        params = params.append('filters', this.serializedCampaignPaymentFilter(criteria.filters));

        if (criteria?.page) {
            params = params.append('page', criteria?.page);
        }
        if (criteria?.pageEntries) {
            params = params.append('page_entries', criteria?.pageEntries);
        }

        const url = environment.apiUrl + ENDPOINT.CAMPAIGN.PAYMENT.GET_PAGINATED;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    private serializedCampaignPaymentFilter(filter: CampaignPaymentFilter | null): string {
        if (!filter) return JSON.stringify({});
        let result = {
            has_usable_voucher_tickets: filter.hasUsableVoucherTickets,
            search_string: filter.advanceSearch || null,
            agency_id: filter.agencyId || null,
            partner_id: filter.partnerId || null,
            paid_at_first_date: filter.paidAtFirstDate
                ? new Date(filter?.paidAtFirstDate).toLocaleDateString('en-CA')
                : null,
            paid_at_last_date: filter.paidAtLastDate
                ? new Date(filter?.paidAtLastDate).toLocaleDateString('en-CA')
                : null,
        };
        return JSON.stringify(result);
    }

    public postCancelOrder(orderId: number, cancelReason?: string): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.CAMPAIGN.ORDER.CANCEL.replace('{order_id}', orderId.toString());
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.post(url, { cancel_reason: cancelReason }, options);
            })
        );
    }
}
