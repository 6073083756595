import { Action, createReducer, on } from '@ngrx/store';
import { Agency } from 'src/app/models/agency.model';
import * as AgencyActions from './agency.actions';

export interface AgencyState {
    agencies: Agency[] | null;
    error: any;
}

export const initialState: AgencyState = {
    agencies: null,
    error: null,
};

export const agencyReducer = createReducer(
    initialState,
    on(AgencyActions.getAgencies, (state, action) => ({ ...state })),
    on(AgencyActions.getAgenciesSuccess, (state, action) => ({ ...state, agencies: action.data })),
    on(AgencyActions.getAgenciesFailure, (state, action) => ({ ...state, error: action.error }))
);

export function reducer(state: AgencyState | undefined, action: Action) {
    return agencyReducer(state, action);
}
