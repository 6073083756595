import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { agencyReducer, AgencyState } from './agency/agency.reducer';
import { campaignReducer, CampaignState } from './campaign/campaign.reducer';
import { orderBalanceReducer, OrderBalanceState } from './order-balance/order-balance.reducer';
import { orderReducer, OrderState } from './order/order.reducer';
import { partnerReducer, PartnerState } from './partner/partner.reducer';
import { supplierReducer, SupplierState } from './supplier/supplier.reducer';
import { BookingState, bookingReducer } from './booking/booking.reducer';

//

export interface State {
    orderBalance: OrderBalanceState;
    agency: AgencyState;
    partner: PartnerState;
    campaign: CampaignState;
    supplier: SupplierState;
    order: OrderState;
    booking: BookingState;
}

export const reducers: ActionReducerMap<State> = {
    orderBalance: orderBalanceReducer,
    agency: agencyReducer,
    partner: partnerReducer,
    campaign: campaignReducer,
    supplier: supplierReducer,
    order: orderReducer,
    booking: bookingReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
