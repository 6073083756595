import { IDeserializable } from './deserializable.model';

export class FeeReceiptOrderBalanceFilterBase {
    periodId!: number;
    partnerId!: number | null;
}

//#region  FeeReceipt Order Balance Summary

export class FeeReceiptOrderBalanceSummaryFilter extends FeeReceiptOrderBalanceFilterBase {}

export class FeeReceiptOrderSummary implements IFeeReceiptOrderSummary {
    public sumAmount!: number;
    public sumNetCommissionFee!: number;
    public feeReceipt!: IFeeReceipt;

    public deserialize(resp: any): FeeReceiptOrderSummary {
        this.sumAmount = resp.sum_amount;
        this.sumNetCommissionFee = resp.sum_net_commission_fee;
        if (resp.order_balance) {
            this.feeReceipt = new FeeReceipt().deserialize(resp.order_balance);
        }
        return this;
    }
}

export interface IFeeReceiptOrderSummary {
    sumAmount: number | null;
    sumNetCommissionFee: number | null;
    feeReceipt: IFeeReceipt | null;
}

export class FeeReceipt implements IFeeReceipt {
    public id!: number;
    public confirmed!: boolean;

    public deserialize(resp: any) {
        this.id = resp.id;
        this.confirmed = resp.confirmed;

        return this;
    }
}

export interface IFeeReceipt {
    id: number | null;
    confirmed: boolean | null;
}

//#endregion

//#region  FeeReceipt Order Balance Paginated
export class FeeReceiptOrderBalancePaginatedCriteria {
    filters!: FeeReceiptOrderBalancePaginatedFilter;
    page!: number;
    pageEntries!: number;
}

export class FeeReceiptOrderBalancePaginatedFilter extends FeeReceiptOrderBalanceFilterBase {}

export class FeeReceiptOrderBalancePaginated implements IDeserializable, IFeeReceiptOrderBalancePaginated {
    page!: number | null;
    pageEntries!: number | null;
    total!: number | null;
    result!: IFeeReceiptOrderBalancePaginatedItem[] | null;
    deserialize(source: any): this {
        this.page = source.page;
        this.pageEntries = source.page_entries;
        this.total = source.total;
        this.result = source.result.map((r: any) => new FeeReceiptOrderBalancePaginatedItem().deserialize(r));
        return this;
    }
}
export interface IFeeReceiptOrderBalancePaginated {
    page: number | null;
    pageEntries: number | null;
    total: number | null;
    result: IFeeReceiptOrderBalancePaginatedItem[] | null;
}

export interface IFeeReceiptOrderBalancePaginatedItem {
    id: number | null;
    itemQuantity: number | null;
    amount: number | null;
    commissionFee: number | null;
    commissionFeeVat: number | null;
    commissionFeeWhTax: number | null;
    netCommissionFee: number | null;
    order: Order | null;
    voucher: Voucher | null;
}

export class FeeReceiptOrderBalancePaginatedItem implements IDeserializable, IFeeReceiptOrderBalancePaginatedItem {
    id!: number;
    itemQuantity!: number;
    amount!: number;
    commissionFee!: number;
    commissionFeeVat!: number;
    commissionFeeWhTax!: number;
    netCommissionFee!: number;
    order!: Order;
    voucher!: Voucher;

    deserialize(source: any): this {
        this.id = source.id;
        this.itemQuantity = source.item_quantity;
        this.amount = source.amount;
        this.commissionFee = source.commission_fee;
        this.commissionFeeVat = source.commission_fee_vat;
        this.commissionFeeWhTax = source.commission_fee_wh_tax;
        this.netCommissionFee = source.net_commission_fee;
        this.order = new Order().deserialize(source.order);
        this.voucher = new Voucher().deserialize(source.voucher);

        return this;
    }
}

export class Order implements IDeserializable {
    id!: number;
    orderCode!: string;
    paidAt!: Date;

    deserialize(source: any): this {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.paidAt = source.paid_at;
        return this;
    }
}

export class Voucher implements IDeserializable {
    id!: number;
    name!: string;
    price!: number;
    commissionFee!: number;
    deserialize(source: any): this {
        this.id = source.id;
        this.name = source.name;
        this.price = source.price;
        this.commissionFee = source.commission_fee;
        return this;
    }
}
//#endregion
