import { Action, createReducer, on } from '@ngrx/store';
import { OrderExcelReportItem, OrderPaginated } from 'src/app/models/order.model';
import * as OrderActions from './order.actions';

export interface OrderState {
    orderPaginated: OrderPaginated | null;
    orderExcelReportList: OrderExcelReportItem[] | null;
    error: any | null;
}

export const initialState: OrderState = {
    orderPaginated: null,
    orderExcelReportList: null,
    error: null,
};

export const orderReducer = createReducer(
    initialState,
    on(OrderActions.getOrderPaginatedSuccess, (state, action) => {
        action.data.result = action.nextPage
            ? [...state.orderPaginated?.result!, ...action.data.result!]
            : action.data.result;
        return {
            ...state,
            orderPaginated: action.data,
        };
    }),
    on(OrderActions.getOrderPaginatedFail, (state, action) => ({
        ...state,
        error: action.error,
    })),
    on(OrderActions.getOrderExcelReportSuccess, (state, action) => ({
        ...state,
        orderExcelReportList: action.data,
    })),
    on(OrderActions.getOrderExcelReportFail, (state, action) => ({
        ...state,
        error: action.error,
    }))
);

export function reducer(state: OrderState | undefined, action: Action) {
    return orderReducer(state, action);
}
