import * as dayjs from 'dayjs-es';
import { IBasePeriod } from './base.model';
import { IDeserializable } from './deserializable.model';

export class PnPeriod implements IBasePeriod, IDeserializable {
    id!: number;
    voucherTicketFirstUsageDate!: Date;
    voucherTicketLastUsageDate!: Date;
    transferDueDate!: Date;

    year!: number;

    deserialize(source: any): this {
        this.id = source.id;
        this.voucherTicketFirstUsageDate = new Date(source.voucher_ticket_first_usage_date);
        this.voucherTicketLastUsageDate = new Date(source.voucher_ticket_last_usage_date);
        this.transferDueDate = new Date(source.transfer_due_date);

        this.year = dayjs(this.transferDueDate).year();

        return this;
    }

    static toYearlyPeriods(periods: PnPeriod[] | null): Map<number, PnPeriod[]> {
        let views = new Map<number, PnPeriod[]>();
        views = periods!.reduce(function (d: any, v) {
            d[v.year] = d[v.year] || [];
            d[v.year].push(v);
            return d;
        }, Object.create({}));
        return views;
    }
}
