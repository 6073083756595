import { DateHelper } from '../helpers/date.helper';
import { IDeserializable } from './deserializable.model';
import { PaginatedBase } from './paginated.model';
import { PnPartner } from './pn-partner.model';

export class PnOrderBalanceFilterBase {
    periodId?: number;
    partnerId?: number;
}

export class PnPnOrderBalanceSummaryFilter extends PnOrderBalanceFilterBase {}

export class PnOrderBalancePaginatedFilter extends PnOrderBalanceFilterBase {}

export class PnOrderBalancePaginatedCriteria {
    filters!: PnOrderBalancePaginatedFilter;
    page!: number;
    pageEntries!: number;
}

export class PnOrderBalanceSummary implements IDeserializable {
    all!: number;
    transfered!: number;
    notTransfered!: number;
    sumNetAmount!: number;

    transferCompleted!: boolean;
    transferIncompleted!: boolean;
    waitingForTransfer!: boolean;

    deserialize(source: any): this {
        if (source.order_balance_quantity) {
            this.all = source.order_balance_quantity.all;
            this.transfered = source.order_balance_quantity.transfered;
            this.notTransfered = source.order_balance_quantity.not_transfered;

            if (this.all === this.transfered) {
                this.transferCompleted = true;
            } else if (this.transfered === 0) {
                this.waitingForTransfer = true;
            } else {
                this.transferIncompleted = true;
            }
        } else {
            this.waitingForTransfer = true;
        }
        this.sumNetAmount = source.sum_net_amount;

        return this;
    }
}

export class PnOrderBalancePaginated extends PaginatedBase<PnOrderBalancePaginatedItem> {
    populateResult(source: any): PnOrderBalancePaginatedItem[] {
        let result = source.result.map((r: any) => new PnOrderBalancePaginatedItem().deserialize(r));
        return result;
    }
}

export class PnOrderBalancePaginatedItem implements IDeserializable {
    id!: number;
    orderBalanceItemQuantity!: number;
    sumNetAmount!: number;
    sumPrice!: number;
    transfered!: boolean;
    transferedAt!: Date;
    transferedAmount!: number;
    transferDocFileUrl!: string;
    partner: PnPartner | undefined;
    bankAccountId!: number;
    bankAccountName!: string;
    bankAccountNumber!: string;
    bankAccountTypeId!: number;
    bankAccountBranch!: string;
    bankId!: number;
    bankName!: string;
    updatedAt!: Date;

    orderBalanceUnconfirmed!: number;

    deserialize(source: any): this {
        this.id = source.id;
        this.orderBalanceItemQuantity = source.order_balance_item_quantity;
        this.sumNetAmount = source.sum_net_amount;
        this.sumPrice = source.sum_price;
        this.transfered = source.transfered;
        this.transferedAt = DateHelper.convertStringToDate(source.transfered_at)!;
        this.transferedAmount = source.transfered_amount;
        this.transferDocFileUrl = source.transfer_doc_file_url;
        this.partner = new PnPartner().deserialize(source.partner);
        this.bankAccountId = source.bank_account.id;
        this.bankAccountName = source.bank_account.account_name;
        this.bankAccountNumber = source.bank_account.account_number;
        this.bankAccountTypeId = source.bank_account.account_type_id;
        this.bankAccountBranch = source.bank_account.branch;
        this.bankId = source.bank_account.bank.id;
        this.bankName = source.bank_account.bank.name;
        this.updatedAt = DateHelper.convertStringToDate(source.updated_at)!;

        this.orderBalanceUnconfirmed = source.partner.order_balance_quantity.unconfirmed;

        return this;
    }
}
