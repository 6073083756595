import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/auth/auth.service';

import { MENUS } from 'src/app/constants/menu.constant';
import { environment } from 'src/environments/environment';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-main-theme',
    templateUrl: './main-theme.component.html',
    styleUrls: ['./main-theme.component.scss'],
})
export class MainThemeComponent implements OnInit {
    constructor(private router: Router, private auth: AuthService) {}

    appVersion: string | null = environment.appVersion;
    isCollapseSidebar: boolean = false;
    icons = {
        faChevronRight: faChevronRight as IconProp,
    };

    menus = MENUS;
    menusCollapse: { [id: string]: boolean } = {};

    ngOnInit(): void {
        this.initMenu();
    }

    private initMenu(): void {
        const userRole = this.auth.getCurrentRole();
        this.menus.forEach((menu) => {
            // Display menu show/hide follow access role.
            menu.isHidden = !this.checkAccessByRole(userRole, menu.accessRoles);
            menu.subMenu?.forEach((submenu) => {
                submenu.isHidden = !this.checkAccessByRole(userRole!, submenu.accessRoles);
            });

            // Adjust submenu menu toggle.
            if (menu.subMenu && menu.subMenu.length > 0) {
                this.menusCollapse[menu.slug!] = true;
            }
        });
    }

    private checkAccessByRole(agencyRole: string | null, pageRoles?: string[]): boolean {
        if (pageRoles === undefined) {
            // Pages is not want any role to access.
            return true;
        }

        if (agencyRole === null) {
            // This not authen
            return false;
        }

        return pageRoles.indexOf(agencyRole) >= 0;
    }
}
