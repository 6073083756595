import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Agency } from 'src/app/models/agency.model';
import { OrderBalancePaginated, OrderBalanceSummary } from 'src/app/models/order-balance.model';
import { OrderBalanceItemSummary } from 'src/app/models/order-summary.model';
import { Period } from 'src/app/models/period.model';
import { TransferDetail } from 'src/app/models/transfer-detail.model';
import { OrderBalanceService } from 'src/app/services/order-balance.service';
import * as OrderBalanaceActions from './order-balance.actions';
import { OrderBalancePaginated as OBPaginated } from 'src/app/models/order-balance-paginated.model';
import { Partner } from 'src/app/models/partner.model';
import {
    PartnerOrderBalancePaginated,
    PartnerOrderBalancePaginatedItem,
    PartnerOrderSummary,
} from 'src/app/models/partner-order-balance.model';
import {
    FeeReceiptOrderBalancePaginated,
    FeeReceiptOrderSummary,
} from 'src/app/models/fee-receipt-order-balance.model';
import { PnPartner as PnPartner } from 'src/app/models/pn-partner.model';
import { PnPeriod } from 'src/app/models/pn-period.model';
import { PnOrderBalancePaginated, PnOrderBalanceSummary } from 'src/app/models/pn-order-balance.model';
import { FeeReceiptSummary } from 'src/app/models/fee-receipt-summary.model';
import { FeeReceiptPaginated } from 'src/app/models/fee-receipt-paginated.model';

@Injectable()
export class OrderBalanceEffects {
    constructor(
        private actions$: Actions,
        private ngxUiLoaderService: NgxUiLoaderService,
        private orderBalanceService: OrderBalanceService
    ) {}

    public getAgencies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getAgencies),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getAgencies(action.periodId).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getAgenciesSuccess({
                                data: resp.data.map((d: any) => new Agency().deserialize(d)),
                            });
                        } else {
                            return OrderBalanaceActions.getAgenciesFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getAgenciesFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getPeriods$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getPeriods),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getPeriods().pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getPeriodsSuccess({
                                data: resp.data.map((d: any) => new Period().deserialize(d)),
                            });
                        } else {
                            return OrderBalanaceActions.getPeriodsFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getPeriodsFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getOrderBalanceSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getOrderBalanceSummary),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getOrderBalanceSummary(action.filters).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getOrderBalanceSummarySuccess({
                                data: new OrderBalanceSummary().deserialize(resp.data),
                            });
                        } else {
                            return OrderBalanaceActions.getOrderBalanceSummaryFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getOrderBalanceSummaryFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getOrderBalancePaginated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getOrderBalancePaginated),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getOrderBalancePaginated(action.criteria).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getOrderBalancePaginatedSuccess({
                                data: new OrderBalancePaginated().deserialize(resp.data),
                                nextPage: action.nextPage,
                            });
                        } else {
                            return OrderBalanaceActions.getOrderBalancePaginatedFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getOrderBalancePaginatedFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public putTransferDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.putTransferDetail),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.putTransferDetail(action.balanceId, action.payload).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.putTransferDetailSuccess({
                                data: new TransferDetail().deserialize(resp.data),
                            });
                        } else {
                            return OrderBalanaceActions.putTransferDetailFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.putTransferDetailFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getOrderSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getOrderBalanceItemSummary),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getOrderSummary(action.periodId!, action.agencyId!).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getOrderBalanceItemSummarySuccess({
                                orderBalanceItemSummary: new OrderBalanceItemSummary().deserialize(resp.data),
                            });
                        } else {
                            return OrderBalanaceActions.getOrderBalanceItemSummaryFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getOrderBalanceItemSummaryFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getOrderPaginated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getOrderPaginated),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getOrderPaginated(action.criteria).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getOrderPaginatedSuccess({
                                orderBalancePaginated: new OBPaginated().deserialize(resp.data),
                                nextPage: action.nextPage,
                            });
                        } else {
                            return OrderBalanaceActions.getOrderPaginatedFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getOrderPaginatedFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    //#region Partner Order Balance
    public getPartners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getPartners),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getPartners(action.periodId).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getPartnersSuccess({
                                partners: resp.data.map((d: any) => new Partner().deserialize(d)),
                            });
                        } else {
                            return OrderBalanaceActions.getPartnersFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(OrderBalanaceActions.getPartnersFail({ error }));
                    })
                );
            })
        )
    );

    public getPnPartners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getPnPartners),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getPnPartners(action.periodId).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getPnPartnersSuccess({
                                data: resp.data.map((d: any) => new PnPartner().deserialize(d)),
                            });
                        } else {
                            return OrderBalanaceActions.getPnPartnersFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getPnPartnersFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getPartnerPeriods$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getPartnerPeriods),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getPartnerPeriods().pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getPartnerPeriodsSuccess({
                                data: resp.data.map((d: any) => new Period().deserialize(d)),
                            });
                        } else {
                            return OrderBalanaceActions.getPartnerPeriodsFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(OrderBalanaceActions.getPartnerPeriodsFail({ error }));
                    })
                );
            })
        )
    );

    public getPnPeriods$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getPnPeriods),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getPnPeriods().pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getPnPeriodsSuccess({
                                data: resp.data.map((d: any) => new PnPeriod().deserialize(d)),
                            });
                        } else {
                            return OrderBalanaceActions.getPnPeriodsFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getPnPeriodsFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getPartnerOrderSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getPartnerOrderSummary),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService
                    .getPartnerOrderBalanceSummary({ periodId: action.periodId, partnerId: action.partnerId })
                    .pipe(
                        map((resp) => {
                            this.ngxUiLoaderService.stop();
                            if (resp.status == 'success') {
                                return OrderBalanaceActions.getPartnerOrderSummarySuccess({
                                    partnerOrderSummary: new PartnerOrderSummary().deserialize(resp.data),
                                });
                            } else {
                                return OrderBalanaceActions.getPartnerOrderSummaryFail({
                                    error: resp.message,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxUiLoaderService.stop();
                            return of(
                                OrderBalanaceActions.getPartnerOrderSummaryFail({
                                    error,
                                })
                            );
                        })
                    );
            })
        )
    );

    public getPartnerOrderBalancePaginated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getPartnerOrderPaginated),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getPartnerOrderBalancePaginated(action.criteria).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getPartnerOrderPaginatedSuccess({
                                partnerOrderBalancePaginated: new PartnerOrderBalancePaginated().deserialize(resp.data),
                                nextPage: action.nextPage,
                            });
                        } else {
                            return OrderBalanaceActions.getPartnerOrderPaginatedFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getPartnerOrderPaginatedFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getPnOrderBalanceSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getPnOrderBalanceSummary),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getPnOrderBalanceSummary(action.filters).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getPnOrderBalanceSummarySuccess({
                                data: new PnOrderBalanceSummary().deserialize(resp.data),
                            });
                        } else {
                            return OrderBalanaceActions.getPnOrderBalanceSummaryFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getPnOrderBalanceSummaryFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    //#endregion

    //#region Fee Receipt Order Balance
    public getFeeReceiptPartners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getFeeReceiptPartners),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getFeeReceiptPartners(action.periodId).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getFeeReceiptPartnersSuccess({
                                partners: resp.data.map((d: any) => new Partner().deserialize(d)),
                            });
                        } else {
                            return OrderBalanaceActions.getFeeReceiptPartnersFail({ error: resp.message });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getFeeReceiptPartnersFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getPnOrderBalancePaginated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getPnOrderBalancePaginated),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getPnOrderBalancePaginated(action.criteria).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getPnOrderBalancePaginatedSuccess({
                                data: new PnOrderBalancePaginated().deserialize(resp.data),
                                nextPage: action.nextPage,
                            });
                        } else {
                            return OrderBalanaceActions.getPnOrderBalancePaginatedFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getPnOrderBalancePaginatedFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getFeeReceiptOrderSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getFeeReceiptOrderSummary),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService
                    .getFeeReceiptOrderBalanceSummary({ periodId: action.periodId, partnerId: action.partnerId })
                    .pipe(
                        map((resp) => {
                            this.ngxUiLoaderService.stop();
                            if (resp.status == 'success') {
                                return OrderBalanaceActions.getFeeReceiptOrderSummarySuccess({
                                    feeReceiptOrderSummary: new FeeReceiptOrderSummary().deserialize(resp.data),
                                });
                            } else {
                                return OrderBalanaceActions.getFeeReceiptOrderSummaryFail({
                                    error: resp.message,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxUiLoaderService.stop();
                            return of(
                                OrderBalanaceActions.getFeeReceiptOrderSummaryFail({
                                    error,
                                })
                            );
                        })
                    );
            })
        )
    );

    public getFeeReceiptOrderBalancePaginated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getFeeReceiptOrderPaginated),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getFeeReceiptOrderBalancePaginated(action.criteria).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getFeeReceiptOrderPaginatedSuccess({
                                feeReceiptOrderBalancePaginated: new FeeReceiptOrderBalancePaginated().deserialize(
                                    resp.data
                                ),
                                nextPage: action.nextPage,
                            });
                        } else {
                            return OrderBalanaceActions.getFeeReceiptOrderPaginatedFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getFeeReceiptOrderPaginatedFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getFeeReceiptSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getFeeReceiptSummary),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService
                    .getFeeReceiptSummary({ periodId: action.periodId, partnerId: action.partnerId })
                    .pipe(
                        map((resp) => {
                            this.ngxUiLoaderService.stop();
                            if (resp.status == 'success') {
                                return OrderBalanaceActions.getFeeReceiptSummarySuccess({
                                    result: new FeeReceiptSummary().deserialize(resp.data),
                                });
                            } else {
                                return OrderBalanaceActions.getFeeReceiptSummaryFail({
                                    error: resp.message,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxUiLoaderService.stop();
                            return of(
                                OrderBalanaceActions.getFeeReceiptSummaryFail({
                                    error,
                                })
                            );
                        })
                    );
            })
        )
    );

    public getFeeReceiptPaginated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getFeeReceiptPaginated),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getFeeReceiptPaginated(action.criteria).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getFeeReceiptPaginatedSuccess({
                                result: new FeeReceiptPaginated().deserialize(resp.data),
                            });
                        } else {
                            return OrderBalanaceActions.getFeeReceiptPaginatedFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getFeeReceiptPaginatedFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getFeeReceiptDoc$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.getFeeReceiptDoc),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.getFeeReceiptDoc(action.feeReceiptId).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.getFeeReceiptDocSuccess({
                                result: resp.data?.file_url,
                            });
                        } else {
                            return OrderBalanaceActions.getFeeReceiptDocFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.getFeeReceiptDocFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public postConfirmFeeReceipt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.postConfirmFeeReceipt),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.postConfirmFeeReceipt(action.feeReceiptId).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.postConfirmFeeReceiptSuccess({
                                result: true,
                            });
                        } else {
                            return OrderBalanaceActions.postConfirmFeeReceiptFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.postConfirmFeeReceiptFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public putPnTransferDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderBalanaceActions.putPnTransferDetail),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderBalanceService.putPnTransferDetail(action.balanceId, action.payload).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderBalanaceActions.putPnTransferDetailSuccess({
                                data: resp.data,
                            });
                        } else {
                            return OrderBalanaceActions.putPnTransferDetailFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderBalanaceActions.putPnTransferDetailFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );
    //#endregion
}
