import { Action, createReducer, on } from '@ngrx/store';
import { Supplier } from 'src/app/models/supplier.model';
import * as SupplierActions from './supplier.actions';

export interface SupplierState {
    suppliers: Supplier[] | null;
    error: any;
}

export const initialState: SupplierState = {
    suppliers: null,
    error: null,
};

export const supplierReducer = createReducer(
    initialState,
    on(SupplierActions.getSuppliers, (state, action) => ({ ...state })),
    on(SupplierActions.getSuppliersSuccess, (state, action) => ({ ...state, suppliers: action.data })),
    on(SupplierActions.getSuppliersFailure, (state, action) => ({ ...state, error: action.error }))
);

export function reducer(state: SupplierState | undefined, action: Action) {
    return supplierReducer(state, action);
}
