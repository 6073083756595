<div class="wrapper">
    <nav id="sidebar" class="sidebar" [ngClass]="{ active: isCollapseSidebar }">
        <div id="logo-wrapper" class="px-3">
            <img class="img-fluid" src="assets/images/tourwow-logo.png" alt="Tourwow Finance {{ appVersion }}" />
        </div>

        <ul class="nav flex-column">
            <ng-container *ngFor="let menu of menus; let i = index">
                <ng-container *ngIf="!menu.isHidden && menu.subMenu">
                    <li>
                        <a
                            (click)="menusCollapse[menu.slug!] = !menusCollapse[menu.slug!]"
                            class="nav-link"
                            [attr.aria-expanded]="!menusCollapse[menu.slug!]"
                            aria-controls="collapseBasic"
                            >{{ menu.name }} <fa-icon [icon]="icons.faChevronRight" class="icon-chev"></fa-icon
                        ></a>
                        <div *ngIf="menu.subMenu" [collapse]="menusCollapse[menu.slug!]" [isAnimated]="true">
                            <ul>
                                <ng-container *ngFor="let sub of menu.subMenu">
                                    <li *ngIf="!sub.isHidden">
                                        <a [routerLink]="sub.link" [routerLinkActive]="'active'" class="nav-link">
                                            {{ sub.name }}</a
                                        >
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </li>
                </ng-container>
                <ng-container *ngIf="!menu.isHidden && !menu.subMenu">
                    <li>
                        <a [routerLink]="menu.link" [routerLinkActive]="'active'" class="nav-link">{{ menu.name }}</a>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </nav>

    <div id="content" [ngClass]="{ active: isCollapseSidebar }">
        <nav class="navbar">
            <div class="container-fluid px-1">
                <button
                    (click)="isCollapseSidebar = !isCollapseSidebar"
                    type="button"
                    id="sidebarCollapse"
                    class="btn text-white"
                >
                    <img src="assets/images/icons/icon-burger.svg" height="18" />
                </button>
            </div>
        </nav>
        <main class="content">
            <div class="container-fluid p-0">
                <router-outlet></router-outlet>
            </div>
            <div
                *ngIf="isCollapseSidebar"
                (click)="isCollapseSidebar = !isCollapseSidebar"
                [ngClass]="{ active: isCollapseSidebar }"
                class="backdrop d-block d-sm-none"
            ></div>
        </main>
    </div>
</div>
