import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ENDPOINT } from '../constants/endpoint.constant';
import { IHttpResponse } from '../models/interfaces/http-response.interface';
import { environment } from 'src/environments/environment';
import { ITokenPayload } from './token-payload.interface';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    redirectUrl: string | null = null;

    private tokenName = 'token';

    constructor(private router: Router, private http: HttpClient) {}

    isLogggedIn(): boolean {
        let token = this.getToken();
        if (token) {
            this.parseJwt(token);
            return true;
        }

        return false;
    }

    isTokenExpired(): boolean {
        const token = this.getToken();
        if (token) {
            const unixTimeSeconds: number = Math.floor(Date.now() / 1000);
            const jwtToken = this.parseJwt(token);
            return jwtToken.exp < unixTimeSeconds;
        }

        return false;
    }

    login(
        username: string | null,
        password: string | null
    ): Observable<{ first_name: string | null; last_name: string | null; mobile_number: string | null } | null> {
        const url = environment.apiUrl + ENDPOINT.AUTH.LOGIN;
        const dataToLogin = { username, password };

        return this.http
            .post<IHttpResponse<{ first_name: string | null; last_name: string | null; mobile_number: string | null }>>(
                url,
                dataToLogin
            )
            .pipe(
                map((response) => {
                    if (response.status !== 'success') {
                        return null;
                    }

                    return response.data;
                })
            );
    }

    sendOTP(mobileNumber: string | null): Observable<string | null> {
        const url = environment.apiUrl + ENDPOINT.AUTH.POST_SEND_OTP;
        const dataToSend = { mobile_number: mobileNumber };

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.post<IHttpResponse<{ token: string | null }>>(url, dataToSend, { headers: headers }).pipe(
            map((response) => {
                if (response.status !== 'success') {
                    return null;
                }

                return response.data.token;
            })
        );
    }

    verifyOTP(
        otpCode: string | null,
        token: String,
        username: string,
        password: string,
        ipAddress: string
    ): Observable<string | null> {
        const url = environment.apiUrl + ENDPOINT.AUTH.POST_VERIFY_OTP;
        const dataToVerify = { otp_code: otpCode, token, ip_address: ipAddress, username, password };

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.post<IHttpResponse<{ token: string | null }>>(url, dataToVerify, { headers: headers }).pipe(
            map((response) => {
                if (response.status !== 'success') {
                    return null;
                }
                return response.data.token;
            }),
            catchError(() => {
                return of(null);
            })
        );
    }

    getIPAddress(): Observable<string | null> {
        const url = 'https://api.bigdatacloud.net/data/client-ip';

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.get<{ ipString: string }>(url, { headers }).pipe(
            map((response) => {
                return response.ipString;
            }),
            catchError(() => {
                return of(null);
            })
        );
    }

    public setToken(token: string): void {
        localStorage.setItem(this.tokenName, token);
    }

    private getToken(): string | null {
        return localStorage.getItem(this.tokenName);
    }

    private deleteToken(): void {
        localStorage.removeItem(this.tokenName);
    }

    public parseJwt(token: string): ITokenPayload {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }

    getAgencyId(): number | null {
        const token = this.getToken();
        if (token) {
            const payload = this.parseJwt(token);
            return payload.user.agency.id;
        }

        return null;
    }

    getTeamNumber(): number | null {
        const token = this.getToken();
        if (token) {
            const payload = this.parseJwt(token);
            return payload.user.agency_member.team;
        }

        return null;
    }

    getCurrentRole(): string | null {
        const token = this.getToken();
        if (token) {
            const payload = this.parseJwt(token);
            return payload.user.agency_member.roles_slug;
        }

        return null;
    }

    getCurrentId(): number | null {
        const token = this.getToken();
        if (token) {
            const payload = this.parseJwt(token);
            return payload.user.agency_member.id;
        }

        return null;
    }

    getCurrentAgencyMember() {
        const token = this.getToken();
        if (token) {
            const payload = this.parseJwt(token);
            return payload.user.agency_member;
        }

        return null;
    }

    logout(): void {
        this.deleteToken();
    }
}
