import { DateHelper } from '../helpers/date.helper';
import { IDeserializable } from './deserializable.model';
import { PaginatedBase } from './paginated.model';

export class OrderBalanceFilterBase {
    periodId!: number;
    agencyId?: number;
}

export class OrderBalanceSummaryFilter extends OrderBalanceFilterBase {}

export class OrderBalancePaginatedFilter extends OrderBalanceFilterBase {}

export class OrderBalancePaginatedCriteria {
    filters: OrderBalancePaginatedFilter = new OrderBalancePaginatedFilter();
    page: number = 1;
    pageEntries: number = 50;
}

export class OrderBalanceSummary implements IDeserializable {
    quantityAll!: number;
    quantityTransfered!: number;
    quantityNotTransfered!: number;
    totalNetCommissionAgency!: number;

    transferCompleted: boolean = false;
    transferIncompleted: boolean = false;
    waitingForTransfer: boolean = false;

    deserialize(source: any): this {
        this.quantityAll = source.order_balance_quantity.all;
        this.quantityTransfered = source.order_balance_quantity.transfered;
        this.quantityNotTransfered = source.order_balance_quantity.not_transfered;

        if (this.quantityAll === this.quantityTransfered) {
            this.transferCompleted = true;
        } else if (this.quantityTransfered === 0) {
            this.waitingForTransfer = true;
        } else {
            this.transferIncompleted = true;
        }
        this.totalNetCommissionAgency = source.sum_net_commission_agency;

        return this;
    }
}

export class OrderBalancePaginated extends PaginatedBase<OrderBalancePaginatedItem> {
    populateResult(source: any): OrderBalancePaginatedItem[] {
        let result = source.result.map((r: any) => new OrderBalancePaginatedItem().deserialize(r));
        return result;
    }
}

export class OrderBalancePaginatedItem implements IDeserializable {
    id!: number;
    receiptItemQuantity!: number;
    sumAmount!: number;
    sumNetCommissionAgency!: number;
    transfered!: boolean;
    transferedAt: Date | null = null;
    transferedAmount: number | null = null;
    transferDocFileUrl: string | null = null;
    agencyId!: number;
    agencyNameTh!: string;
    agencyNameEn!: string;
    bankAccountId!: number;
    bankAccountName!: string;
    bankAccountNumber!: string;
    bankAccountTypeId!: number;
    bankAccountBranch!: string;
    bankId!: number;
    bankName!: string;
    updatedAt!: Date;

    orderBalanceUnconfirmed!: number;

    deserialize(source: any): this {
        this.id = source.id;
        this.receiptItemQuantity = source.receipt_item_quantity;
        this.sumAmount = source.sum_amount;
        this.sumNetCommissionAgency = source.sum_net_commission_agency;
        this.transfered = source.transfered;
        this.transferedAt = DateHelper.convertStringToDate(source.transfered_at);
        this.transferedAmount = source.transfered_amount;
        this.transferDocFileUrl = source.transfer_doc_file_url;
        this.agencyId = source.agency.id;
        this.agencyNameTh = source.agency.name_th;
        this.agencyNameEn = source.agency.name_en;
        this.bankAccountId = source.bank_account.id;
        this.bankAccountName = source.bank_account.account_name;
        this.bankAccountNumber = source.bank_account.account_number;
        this.bankAccountTypeId = source.bank_account.account_type_id;
        this.bankAccountBranch = source.bank_account.branch;
        this.bankId = source.bank_account.bank.id;
        this.bankName = source.bank_account.bank.name;
        this.updatedAt = DateHelper.convertStringToDate(source.updated_at)!;

        this.orderBalanceUnconfirmed = +source.agency.order_balance_quantity.unconfirmed;

        return this;
    }
}
