import { Action, createReducer, on } from '@ngrx/store';
import { Payment, PaymentPaginatedResult, PaymentQuantity } from 'src/app/models/campaign.model';
import * as CampaignActions from './campaign.actions';

export interface CampaignState {
    payments: Payment[] | null;
    paymentQuantity: PaymentQuantity | null;
    paymentPaginatedItems: PaymentPaginatedResult[] | null;
    paymentPaginatedItemTotal: number | null;
    error: any;
}

export const initialState: CampaignState = {
    payments: null,
    paymentQuantity: null,
    paymentPaginatedItems: null,
    paymentPaginatedItemTotal: null,
    error: null,
};

export const campaignReducer = createReducer(
    initialState,

    on(CampaignActions.getPayments, (state, action) => ({ ...state })),
    on(CampaignActions.getPaymentsSuccess, (state, action) => ({ ...state, payments: action.data })),
    on(CampaignActions.getPaymentsFailure, (state, action) => ({ ...state, error: action.error })),

    on(CampaignActions.getPaymentQuantity, (state, action) => ({ ...state })),
    on(CampaignActions.getPaymentQuantitySuccess, (state, action) => ({ ...state, paymentQuantity: action.data })),
    on(CampaignActions.getPaymentQuantityFailure, (state, action) => ({ ...state, error: action.error })),

    on(CampaignActions.getPaymentPaginated, (state, action) => ({ ...state })),
    on(CampaignActions.getPaymentPaginatedSuccess, (state, action) => {
        if (!action.data.result) {
            return { ...state };
        }
        let items = action.data.result;
        if (action.nextPage && state.paymentPaginatedItems?.length) {
            items = state.paymentPaginatedItems.concat(items);
        }
        return {
            ...state,
            paymentPaginatedItems: items,
            paymentPaginatedItemTotal: action.data.total,
        };
    }),
    on(CampaignActions.getPaymentPaginatedFailure, (state, action) => ({ ...state, error: action.error }))
);

export function reducer(state: CampaignState | undefined, action: Action) {
    return campaignReducer(state, action);
}
