import { Action, createReducer, on } from '@ngrx/store';
import { Partner } from 'src/app/models/partner.model';
import * as PartnerActions from './partner.actions';

export interface PartnerState {
    partners: Partner[] | null;
    error: any;
}

export const initialState: PartnerState = {
    partners: null,
    error: null,
};

export const partnerReducer = createReducer(
    initialState,
    on(PartnerActions.getPartners, (state, action) => ({ ...state })),
    on(PartnerActions.getPartnersSuccess, (state, action) => ({ ...state, partners: action.data })),
    on(PartnerActions.getPartnersFailure, (state, action) => ({ ...state, error: action.error }))
);

export function reducer(state: PartnerState | undefined, action: Action) {
    return partnerReducer(state, action);
}
