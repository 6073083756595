import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Supplier } from 'src/app/models/supplier.model';
import { SupplierService } from 'src/app/services/supplier.service';
import * as SupplierActions from './supplier.actions';

@Injectable()
export class SupplierEffects {
    constructor(
        private actions$: Actions,
        private ngxUiLoaderService: NgxUiLoaderService,
        private supplierService: SupplierService
    ) {}

    public getSuppliers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SupplierActions.getSuppliers),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.supplierService.getSuppliers().pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.length > 0) {
                            return SupplierActions.getSuppliersSuccess({
                                data: resp.map((d: any) => new Supplier().deserialize(d)),
                            });
                        } else {
                            return SupplierActions.getSuppliersFailure({
                                error: 'Get supplier failure.',
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            SupplierActions.getSuppliersFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );
}
