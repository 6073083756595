import { IDeserializable } from './deserializable.model';
import { PaginatedBase } from './paginated.model';

export interface CampaignPaymentPaginatedCriteria {
    page: number;
    pageEntries: number;
    filters: CampaignPaymentFilter;
}

export interface CampaignPaymentFilter {
    advanceSearch: string | undefined;
    agencyId: any | null;
    partnerId: any | null;
    paidAtFirstDate: any | null;
    paidAtLastDate: any | null;
    hasUsableVoucherTickets: boolean | null;
}

export class Payment implements IDeserializable {
    id!: number;
    originalAmount!: number;
    amount!: number;
    twFee!: number;
    contactName!: string;
    phoneNumber!: string;
    usageDate!: string;
    hasUsableVoucherTickets!: boolean;
    voucherTicketQuantity!: VoucherTicketQuantity;
    order!: Order;
    campaign!: Campaign;
    voucher!: Voucher;
    usedVoucherTickets!: UsedVoucherTicket[];
    agency!: Agency;
    productOwnerPartner!: ProductOwnerPartner;
    omiseData!: OmiseData | null;

    deserialize(source: any): this {
        this.id = source.id;
        this.originalAmount = Number(source.original_amount);
        this.amount = Number(source.amount);
        this.twFee = Number(source.tw_fee);
        this.contactName = source.contact_name;
        this.phoneNumber = source.phone_number;
        this.usageDate = source.usage_date;
        this.hasUsableVoucherTickets = source.has_usable_voucher_tickets;
        this.voucherTicketQuantity = new VoucherTicketQuantity().deserialize(source.voucher_ticket_quantity);
        this.order = new Order().deserialize(source.order);
        this.campaign = new Campaign().deserialize(source.campaign);
        this.voucher = new Voucher().deserialize(source.voucher);
        this.usedVoucherTickets = source.used_voucher_tickets.map((x: any) => new UsedVoucherTicket().deserialize(x));
        this.agency = new Agency().deserialize(source.agency);
        this.productOwnerPartner = new ProductOwnerPartner().deserialize(source.product_owner_partner);
        this.omiseData = source.omise_data != null ? new OmiseData().deserialize(source.omise_data) : null;

        return this;
    }
}

export class PaymentQuantity implements IDeserializable {
    all!: number;
    hasUsableVoucherTickets!: number;

    deserialize(source: any): this {
        this.all = source.payment_quantity.all;
        this.hasUsableVoucherTickets = source.payment_quantity.has_usable_voucher_tickets;
        return this;
    }
}

export class PaymentPaginated extends PaginatedBase<PaymentPaginatedResult> {
    populateResult(source: any): PaymentPaginatedResult[] {
        let result = source.result.map((r: any) => new PaymentPaginatedResult().deserialize(r));
        return result;
    }
}

export class PaymentPaginatedResult implements IDeserializable {
    id!: number;
    contactName!: string;
    phoneNumber!: string;
    usageDate!: Date;
    hasUsableVoucherTickets!: boolean;
    originalAmount!: number;
    twFee!: number;
    amount!: number;
    voucherTicketQuantity!: VoucherTicketQuantity;
    order!: Order;
    campaign!: Campaign;
    voucher!: Voucher;
    usedVoucherTickets!: UsedVoucherTicket[];
    omiseData!: OmiseData | null;
    agency!: Agency;
    productOwnerPartner!: Agency;

    deserialize(source: any): this {
        this.id = source.id;
        this.contactName = source.contact_name;
        this.phoneNumber = source.phone_number;
        this.usageDate = source.usage_date;
        this.hasUsableVoucherTickets = source.has_usable_voucher_tickets;
        this.originalAmount = Number(source.original_amount);
        this.twFee = Number(source.tw_fee);
        this.amount = Number(source.amount);
        this.voucherTicketQuantity = new VoucherTicketQuantity().deserialize(source.voucher_ticket_quantity);
        this.order = new Order().deserialize(source.order);
        this.campaign = new Campaign().deserialize(source.campaign);
        this.voucher = new Voucher().deserialize(source.voucher);
        this.usedVoucherTickets = source.used_voucher_tickets.map((m: any) => new UsedVoucherTicket().deserialize(m));
        this.omiseData = source.omise_data != null ? new OmiseData().deserialize(source.omise_data) : null;
        this.agency = new Agency().deserialize(source.agency);
        this.productOwnerPartner = new Agency().deserialize(source.product_owner_partner);
        return this;
    }
}

export class VoucherTicketQuantity implements IDeserializable {
    total!: number;
    used!: number;
    unused!: number;
    expired!: number;

    deserialize(source: any): this {
        this.total = source.total;
        this.used = source.used;
        this.unused = source.unused;
        this.expired = source.expired;
        return this;
    }
}

export class Order implements IDeserializable {
    id!: number;
    orderCode!: string;
    paidAt!: string;

    isCancel: boolean = false;
    cancelAt?: Date;
    cancelReason?: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.paidAt = source.paid_at;

        this.isCancel = source.canceled;
        this.cancelAt = new Date(source.canceled_at);
        this.cancelReason = source.cancel_reason;
        return this;
    }
}

export class Campaign implements IDeserializable {
    id!: number;
    campaignCode!: string;
    name!: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.campaignCode = source.campaign_code;
        this.name = source.name;
        return this;
    }
}

export class Voucher implements IDeserializable {
    id!: number;
    name!: string;
    voucherEndDate!: string;
    price!: number;
    commission!: number;
    commissionFee!: number;
    commissionAgency!: number;

    deserialize(source: any): this {
        this.id = source.id;
        this.name = source.name;
        this.voucherEndDate = source.voucher_end_date;
        this.price = Number(source.price);
        this.commission = Number(source.commission);
        this.commissionFee = Number(source.commission_fee);
        this.commissionAgency = Number(source.commission_agency);
        return this;
    }
}

export class UsedByPartnerMember implements IDeserializable {
    id!: number;
    firstName!: string;
    lastName!: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.firstName = source.first_name;
        this.lastName = source.last_name;
        return this;
    }
}

export class UsedVoucherTicket implements IDeserializable {
    id!: number;
    ticketCode!: string;
    usedAt!: string;
    usedByPartnerMember!: UsedByPartnerMember;

    deserialize(source: any): this {
        this.id = source.id;
        this.ticketCode = source.ticket_code;
        this.usedAt = source.used_at;
        this.usedByPartnerMember = source.used_by_partner_member;
        return this;
    }
}

export class OmiseData implements IDeserializable {
    chargeId!: string;
    amount!: number;
    fee!: number;
    feeVat!: number;
    cardBrand!: string;
    cardType!: string;
    source!: string;
    transactionType!: string;
    transactionAmount!: number;
    transactionCreatedAt!: string;
    transactionTransferableAt!: string;

    deserialize(source: any): this {
        this.chargeId = source.charge_id;
        this.amount = source.amount;
        this.fee = source.fee;
        this.feeVat = source.fee_vat;
        this.cardBrand = source.card_brand;
        this.cardType = source.card_type;
        this.source = source.source;
        this.transactionType = source.transaction_type;
        this.transactionAmount = source.transaction_amount;
        this.transactionCreatedAt = source.transaction_created_at;
        this.transactionTransferableAt = source.transaction_transferable_at;

        return this;
    }
}

export class Agency {
    id!: number | null;
    nameEN!: string | null;
    nameTH!: string | null;
    officeTelephoneNumber!: string | null;
    public deserialize(source: any): Agency {
        this.id = source.id;
        this.nameEN = source.name_en;
        this.nameTH = source.name_th;
        this.officeTelephoneNumber = source.office_telephone_number;
        return this;
    }
}

export class ProductOwnerPartner implements IDeserializable {
    id!: number;
    nameTh!: string;
    nameEn!: string;
    officeTelephoneNumber!: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.nameTh = source.name_th;
        this.nameEn = source.name_en;
        this.officeTelephoneNumber = source.office_telephone_number;

        return this;
    }
}
