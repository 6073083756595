export class Country {
    id!: number;
    nameTh!: string;
    nameEn!: string;
    iconUrl!: string;

    deserialize(data: any): this {
        this.id = data.id;
        this.nameTh = data.name_th;
        this.nameEn = data.name_en;
        this.iconUrl = data.icon_url;
        return this;
    }
}
