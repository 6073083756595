import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
        const url: string = state.url;
        return this.checkLogin(route, url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
        return this.canActivate(route, state);
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const url = `/${route.path}`;
        return this.checkLogin(null, url) && this.checkTokenExpired();
    }

    private checkLogin(route: ActivatedRouteSnapshot | null, url: string): true | UrlTree {
        if (this.authService.isLogggedIn()) {
            if (route && route.data && route.data.accessRoles) {
                const pageRole: string[] = route.data.accessRoles;
                const currentRole = this.authService.getCurrentRole();
                if (currentRole && pageRole.indexOf(currentRole) === -1) {
                    return this.router.parseUrl('/forbidden');
                }
            }

            return true;
        }

        this.authService.redirectUrl = url;
        return this.router.parseUrl('/login');
    }

    private checkTokenExpired(): true | UrlTree {
        if (!this.authService.isTokenExpired()) {
            return true;
        }

        return this.router.parseUrl('/login');
    }
}
