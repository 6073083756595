import { createAction, props } from '@ngrx/store';
import { Agency } from 'src/app/models/agency.model';
import {
    OrderBalancePaginated,
    OrderBalancePaginatedCriteria,
    OrderBalanceSummary,
    OrderBalanceSummaryFilter,
} from 'src/app/models/order-balance.model';
import { Period } from 'src/app/models/period.model';
import { TransferDetail, TransferDetailPayload } from 'src/app/models/transfer-detail.model';
import { IOrderBalanceItemSummary } from 'src/app/models/order-summary.model';
import { IOrderBalancePaginated } from 'src/app/models/order-balance-paginated.model';
import {
    IPartnerOrderBalancePaginated,
    IPartnerOrderSummary,
    PartnerOrderBalancePaginatedCriteria,
} from 'src/app/models/partner-order-balance.model';
import { Partner } from 'src/app/models/partner.model';
import {
    FeeReceiptOrderBalancePaginatedCriteria,
    IFeeReceiptOrderBalancePaginated,
    IFeeReceiptOrderSummary,
} from 'src/app/models/fee-receipt-order-balance.model';
import { PnPartner } from 'src/app/models/pn-partner.model';
import { PnPeriod } from 'src/app/models/pn-period.model';
import {
    PnOrderBalancePaginated,
    PnOrderBalancePaginatedCriteria,
    PnOrderBalancePaginatedFilter,
    PnOrderBalanceSummary,
} from 'src/app/models/pn-order-balance.model';
import { FeeReceiptSummary } from 'src/app/models/fee-receipt-summary.model';
import { FeeReceiptPaginated, FeeReceiptPaginatedCriteria } from 'src/app/models/fee-receipt-paginated.model';

export const getAgencies = createAction('[Order Balance] Get agencies.', props<{ periodId: number }>());
export const getAgenciesSuccess = createAction('[Order Balance] Get agencies successful.', props<{ data: Agency[] }>());
export const getAgenciesFail = createAction('[Order Balance] Get agencies failure.', props<{ error: any }>());

export const getPeriods = createAction('[Order Balance] Get periods.');
export const getPeriodsSuccess = createAction('[Order Balance] Get periods successful.', props<{ data: Period[] }>());
export const getPeriodsFail = createAction('[Order Balance] Get periods failure.', props<{ error: any }>());

export const getOrderBalanceSummary = createAction(
    '[Order Balance] Get order balance summary.',
    props<{ filters: OrderBalanceSummaryFilter }>()
);
export const getOrderBalanceSummarySuccess = createAction(
    '[Order Balance] Get order balance summary successful.',
    props<{ data: OrderBalanceSummary }>()
);
export const getOrderBalanceSummaryFail = createAction(
    '[Order Balance] Get order balance summary failure.',
    props<{ error: any }>()
);

export const getOrderBalancePaginated = createAction(
    '[Order Balance] Get order balance paginated.',
    props<{ criteria: OrderBalancePaginatedCriteria; nextPage?: boolean }>()
);
export const getOrderBalancePaginatedSuccess = createAction(
    '[Order Balance] Get order balance paginated successful.',
    props<{ data: OrderBalancePaginated; nextPage?: boolean }>()
);
export const getOrderBalancePaginatedFail = createAction(
    '[Order Balance] Get order balance paginated failure.',
    props<{ error: any }>()
);

export const putTransferDetail = createAction(
    '[Order Balance] Put transfer detail.',
    props<{ balanceId: number; payload: TransferDetailPayload }>()
);
export const putTransferDetailSuccess = createAction(
    '[Order Balance] Put transfer detail successful.',
    props<{ data: TransferDetail }>()
);
export const putTransferDetailFail = createAction(
    '[Order Balance] Put transfer detail failure.',
    props<{ error: any }>()
);

export const getOrderBalanceItemSummary = createAction(
    '[Order Balance] Get order balance item summary.',
    props<{ periodId: number | null; agencyId: number | null }>()
);
export const getOrderBalanceItemSummarySuccess = createAction(
    '[Order Balance] Get order balance item summary successful.',
    props<{ orderBalanceItemSummary: IOrderBalanceItemSummary }>()
);
export const getOrderBalanceItemSummaryFail = createAction(
    '[Order Balance] Get order balance item summary failure.',
    props<{ error: any }>()
);

export const getOrderPaginated = createAction(
    '[Order Balance] Get order paginated.',
    props<{ criteria: OrderBalancePaginatedCriteria; nextPage?: boolean }>()
);
export const getOrderPaginatedSuccess = createAction(
    '[Order Balance] Get order paginated successful.',
    props<{ orderBalancePaginated: IOrderBalancePaginated; nextPage?: boolean }>()
);
export const getOrderPaginatedFail = createAction(
    '[Order Balance] Get order paginated failure.',
    props<{ error: any }>()
);

//#region Partner Order Balance

export const getPartnerPeriods = createAction('[Order Balance] Get parter periods.');
export const getPartnerPeriodsSuccess = createAction(
    '[Order Balance] Get parter periods successful.',
    props<{ data: Period[] }>()
);
export const getPartnerPeriodsFail = createAction(
    '[Order Balance] Get parter periods failure.',
    props<{ error: any }>()
);

export const getPartners = createAction('[Order Balance] Get partners.', props<{ periodId: number }>());
export const getPartnersSuccess = createAction(
    '[Order Balance] Get partners successful.',
    props<{ partners: Partner[] }>()
);
export const getPartnersFail = createAction('[Order Balance] Get partners failure.', props<{ error: any }>());

export const getPartnerOrderSummary = createAction(
    '[Order Balance] Get partner order summary.',
    props<{ periodId: number; partnerId: number | null }>()
);
export const getPartnerOrderSummarySuccess = createAction(
    '[Order Balance] Get partner order summary successful.',
    props<{ partnerOrderSummary: IPartnerOrderSummary }>()
);
export const getPartnerOrderSummaryFail = createAction(
    '[Order Balance] Get partner order summary failure.',
    props<{ error: any }>()
);

export const getPartnerOrderPaginated = createAction(
    '[Order Balance] Get partner order paginated.',
    props<{ criteria: PartnerOrderBalancePaginatedCriteria; nextPage?: boolean }>()
);
export const getPartnerOrderPaginatedSuccess = createAction(
    '[Order Balance] Get partner order paginated successful.',
    props<{ partnerOrderBalancePaginated: IPartnerOrderBalancePaginated; nextPage?: boolean }>()
);
export const getPartnerOrderPaginatedFail = createAction(
    '[Order Balance] Get partner order paginated failure.',
    props<{ error: any }>()
);
//#endregion

//#region Fee Receipt Order Balance

export const getFeeReceiptPartners = createAction(
    '[Order Balance] Get fee receipt partners.',
    props<{ periodId: number }>()
);
export const getFeeReceiptPartnersSuccess = createAction(
    '[Order Balance] Get fee receipt partners successful.',
    props<{ partners: Partner[] }>()
);
export const getFeeReceiptPartnersFail = createAction(
    '[Order Balance] Get fee receipt partners failure.',
    props<{ error: any }>()
);

export const getFeeReceiptOrderSummary = createAction(
    '[Order Balance] Get fee receipt order summary.',
    props<{ periodId: number; partnerId: number | null }>()
);
export const getFeeReceiptOrderSummarySuccess = createAction(
    '[Order Balance] Get fee receipt order summary successful.',
    props<{ feeReceiptOrderSummary: IFeeReceiptOrderSummary }>()
);
export const getFeeReceiptOrderSummaryFail = createAction(
    '[Order Balance] Get fee receipt order summary failure.',
    props<{ error: any }>()
);

export const getFeeReceiptOrderPaginated = createAction(
    '[Order Balance] Get fee receipt order paginated.',
    props<{ criteria: FeeReceiptOrderBalancePaginatedCriteria; nextPage?: boolean }>()
);
export const getFeeReceiptOrderPaginatedSuccess = createAction(
    '[Order Balance] Get fee receipt order paginated successful.',
    props<{ feeReceiptOrderBalancePaginated: IFeeReceiptOrderBalancePaginated; nextPage?: boolean }>()
);
export const getFeeReceiptOrderPaginatedFail = createAction(
    '[Order Balance] Get fee receipt order paginated failure.',
    props<{ error: any }>()
);

export const getFeeReceiptSummary = createAction(
    '[Order Balance] Get fee receipt summary.',
    props<{ periodId: number; partnerId: number | null }>()
);
export const getFeeReceiptSummarySuccess = createAction(
    '[Order Balance] Get fee receipt summary successful.',
    props<{ result: FeeReceiptSummary }>()
);
export const getFeeReceiptSummaryFail = createAction(
    '[Order Balance] Get fee receipt summary failure.',
    props<{ error: any }>()
);

export const getFeeReceiptPaginated = createAction(
    '[Order Balance] Get fee receipt paginated.',
    props<{ criteria: FeeReceiptPaginatedCriteria; nextPage?: boolean }>()
);
export const getFeeReceiptPaginatedSuccess = createAction(
    '[Order Balance] Get fee receipt paginated successful.',
    props<{ result: FeeReceiptPaginated; nextPage?: boolean }>()
);
export const getFeeReceiptPaginatedFail = createAction(
    '[Order Balance] Get fee receipt paginated failure.',
    props<{ error: any }>()
);

export const getFeeReceiptDoc = createAction('[Order Balance] Get fee receipt doc.', props<{ feeReceiptId: number }>());
export const getFeeReceiptDocSuccess = createAction(
    '[Order Balance] Get fee receipt doc successful.',
    props<{ result: string }>()
);
export const getFeeReceiptDocFail = createAction(
    '[Order Balance] Get fee receipt doc failure.',
    props<{ error: any }>()
);

export const postConfirmFeeReceipt = createAction(
    '[Order Balance] Post confirm fee receipt.',
    props<{ feeReceiptId: number }>()
);
export const postConfirmFeeReceiptSuccess = createAction(
    '[Order Balance] Post confirm fee receipt successful.',
    props<{ result: boolean }>()
);
export const postConfirmFeeReceiptFail = createAction(
    '[Order Balance] Post confirm fee receipt failure.',
    props<{ error: any }>()
);

//#endregion

export const getPnPartners = createAction('[Order Balance] Get pn partners.', props<{ periodId: number }>());
export const getPnPartnersSuccess = createAction(
    '[Order Balance] Get pn partners successful.',
    props<{ data: PnPartner[] }>()
);
export const getPnPartnersFail = createAction('[Order Balance] Get pn partners failure.', props<{ error: any }>());

export const getPnPeriods = createAction('[Order Balance] Get pn periods.');
export const getPnPeriodsSuccess = createAction(
    '[Order Balance] Get pn periods successful.',
    props<{ data: PnPeriod[] }>()
);
export const getPnPeriodsFail = createAction('[Order Balance] Get pn periods failure.', props<{ error: any }>());

export const getPnOrderBalanceSummary = createAction(
    '[Order Balance] Get pn order balance summary.',
    props<{ filters: PnOrderBalancePaginatedFilter }>()
);
export const getPnOrderBalanceSummarySuccess = createAction(
    '[Order Balance] Get pn order balance summary successful.',
    props<{ data: PnOrderBalanceSummary }>()
);
export const getPnOrderBalanceSummaryFail = createAction(
    '[Order Balance] Get pn order balance summary failure.',
    props<{ error: any }>()
);

export const getPnOrderBalancePaginated = createAction(
    '[Order Balance] Get pn order balance paginated.',
    props<{ criteria: PnOrderBalancePaginatedCriteria; nextPage?: boolean }>()
);
export const getPnOrderBalancePaginatedSuccess = createAction(
    '[Order Balance] Get pn order balance paginated successful.',
    props<{ data: PnOrderBalancePaginated; nextPage?: boolean }>()
);
export const getPnOrderBalancePaginatedFail = createAction(
    '[Order Balance] Get pn order balance paginated failure.',
    props<{ error: any }>()
);

export const putPnTransferDetail = createAction(
    '[Order Balance] Put pn transfer detail.',
    props<{ balanceId: number; payload: TransferDetailPayload }>()
);
export const putPnTransferDetailSuccess = createAction(
    '[Order Balance] Put pn transfer detail successful.',
    props<{ data: TransferDetail }>()
);
export const putPnTransferDetailFail = createAction(
    '[Order Balance] Put pn transfer detail failure.',
    props<{ error: any }>()
);
