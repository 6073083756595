import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrderExcelReportItem, OrderPaginated } from 'src/app/models/order.model';
import { OrderService } from 'src/app/services/order.service';
import * as OrderActions from './order.actions';

@Injectable()
export class OrderEffects {
    constructor(
        private actions$: Actions,
        private ngxUiLoaderService: NgxUiLoaderService,
        private orderService: OrderService
    ) {}

    public getOrdePaginated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.getOrderPaginated),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderService.getOrderPaginated(action.criteria).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderActions.getOrderPaginatedSuccess({
                                data: new OrderPaginated().deserialize(resp.data),
                                nextPage: action.nextPage,
                            });
                        } else {
                            return OrderActions.getOrderPaginatedFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderActions.getOrderPaginatedFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    public getOrdeExcelReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.getOrderExcelReport),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.orderService.getOrderExcelReport(action.filters).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return OrderActions.getOrderExcelReportSuccess({
                                data: resp.data.map((m: any) => new OrderExcelReportItem().deserialize(m)),
                            });
                        } else {
                            return OrderActions.getOrderExcelReportFail({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            OrderActions.getOrderExcelReportFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );
}
