import { IDeserializable } from './deserializable.model';
import { PaginatedBase } from './paginated.model';
import { Partner } from './partner.model';

export class FeeReceiptFilterBase {
    periodId!: number;
    partnerId!: number | null;
}

export class FeeReceiptPaginatedFilter extends FeeReceiptFilterBase {}

export class FeeReceiptPaginatedCriteria {
    filters!: FeeReceiptPaginatedFilter;
    page!: number;
    pageEntries!: number;
}

export class FeeReceiptPaginated extends PaginatedBase<FeeReceiptPaginatedItem> {
    populateResult(source: any): FeeReceiptPaginatedItem[] {
        let result = source.result.map((r: any) => new FeeReceiptPaginatedItem().deserialize(r));
        return result;
    }
}

export class FeeReceiptPaginatedItem implements IDeserializable {
    id!: number;
    confirmed!: boolean;
    productOwnerreceiptTypeSlug!: string;
    receiptItemQuantity!: number;
    sumAmount!: number;
    sumNetCommissionFee!: number;
    whTaxDocFileUrl!: string;
    partner!: Partner;
    updatedAt!: Date;
    hasWhTaxDoc!: boolean;

    deserialize(source: any): this {
        this.id = source.id;
        this.confirmed = source.confirmed;
        this.productOwnerreceiptTypeSlug = source.product_owner_receipt_type_slug;
        this.receiptItemQuantity = source.receipt_item_quantity;
        this.sumAmount = source.sum_amount;
        this.sumNetCommissionFee = source.sum_net_commission_fee;
        this.whTaxDocFileUrl = source.wh_tax_doc_file_url;
        this.partner = new Partner().deserialize(source.product_owner_partner);
        this.updatedAt = source.updated_at;
        this.hasWhTaxDoc = this.productOwnerreceiptTypeSlug === 'receipt_wh_tax' && this.whTaxDocFileUrl != null;

        return this;
    }
}
