import { createAction, props } from '@ngrx/store';
import {
    OrderExcelReportItem,
    OrderPaginated,
    OrderPaginatedCriteria,
    OrderPaginatedFilter,
} from 'src/app/models/order.model';

export const getOrderPaginated = createAction(
    '[Order] Get order paginated.',
    props<{ criteria: OrderPaginatedCriteria; nextPage?: boolean }>()
);
export const getOrderPaginatedSuccess = createAction(
    '[Order] Get order paginated successful.',
    props<{ data: OrderPaginated; nextPage?: boolean }>()
);
export const getOrderPaginatedFail = createAction('[Order] Get order paginated failure.', props<{ error: any }>());

export const getOrderExcelReport = createAction(
    '[Order] Get order excel report.',
    props<{ filters: OrderPaginatedFilter }>()
);
export const getOrderExcelReportSuccess = createAction(
    '[Order] Get order excel report successful.',
    props<{ data: OrderExcelReportItem[] }>()
);
export const getOrderExcelReportFail = createAction('[Order] Get order excel report failure.', props<{ error: any }>());
