const ROLES = {
    ADMIN: 'admin',
    FINANCE: 'finance',
    SALE: 'sale',
    OP: 'op',
    TELESALES: 'telesales',
};

export const ACCESS_ROLES = {
    ALL: [ROLES.ADMIN, ROLES.FINANCE, ROLES.SALE, ROLES.OP, ROLES.TELESALES],
    PROGRAM_TOUR_LIST_PAGE: [ROLES.ADMIN, ROLES.SALE, ROLES.FINANCE, ROLES.OP, ROLES.TELESALES],
    WORKLIST_GROUP: [ROLES.ADMIN, ROLES.SALE, ROLES.FINANCE, ROLES.TELESALES],
    CASH_INFLOW_REPORT: [ROLES.ADMIN, ROLES.FINANCE],
    FINANCE_MANAGEMENT_GROUP: [ROLES.ADMIN, ROLES.FINANCE],
    PRODUCT_MANAGEMENT_GROUP: [ROLES.ADMIN],
    PIPELINE_MANAGEMENT_GROUP: [ROLES.ADMIN],
    CUSTOMER_RELATION_LIST: [ROLES.ADMIN, ROLES.TELESALES],
    //
    ORDER_CREATE_PAGE: [ROLES.ADMIN, ROLES.SALE, ROLES.FINANCE, ROLES.OP, ROLES.TELESALES],
    ORDER_EDIT_PAGE: [ROLES.ADMIN, ROLES.SALE, ROLES.FINANCE, ROLES.OP, ROLES.TELESALES],
    //
    ORDER_LIST_PAGE: [ROLES.ADMIN, ROLES.FINANCE],
    ORDER_PROGRAM_LIST_PAGE: [ROLES.ADMIN, ROLES.FINANCE],
    ORDER_BALANCE_LIST_PAGE: [ROLES.ADMIN, ROLES.FINANCE],
    AGENCY_COMMISSION_PAGE: [ROLES.ADMIN, ROLES.FINANCE],
    ORDER_VOUCHER_LIST_PAGE: [ROLES.ADMIN, ROLES.FINANCE],
    SELLER_VOUCHER_PAGE: [ROLES.ADMIN, ROLES.FINANCE],
    FEE_LIST_PAGE: [ROLES.ADMIN, ROLES.FINANCE],
    TOURWOW_FEE_PAGE: [ROLES.ADMIN, ROLES.FINANCE],
    TW_ORDER_LIST_PAGE: [ROLES.ADMIN, ROLES.FINANCE],
    //
    KEYWORD_LIST_PAGE: [ROLES.ADMIN],
    KEYWORD_PAGE: [ROLES.ADMIN],
    TW_ORDER_MATCHING_LIST_PAGE: [ROLES.ADMIN],
    PERIOD_MANAGEMENT_LIST_PAGE: [ROLES.ADMIN],
    CASH_OUTFLOW_REPORT_LIST_PAGE: [ROLES.ADMIN, ROLES.FINANCE],
    COUNT_LEADS_REPORT: [ROLES.ADMIN, ROLES.FINANCE, ROLES.SALE, ROLES.OP, ROLES.TELESALES],
    SURVEY_REPORT: [ROLES.ADMIN, ROLES.FINANCE, ROLES.SALE, ROLES.OP, ROLES.TELESALES],
    OTP_LOGIN_REPORT: [ROLES.ADMIN],
    CRM_KPI_REPORT: [ROLES.ADMIN, ROLES.FINANCE, ROLES.SALE, ROLES.OP, ROLES.TELESALES],
    //
    LEAD_DETAIL_PAGE: [ROLES.ADMIN, ROLES.SALE, ROLES.FINANCE, ROLES.OP, ROLES.TELESALES],
    TELESALES_REPORT: [ROLES.ADMIN],
    ORDERS_COUNT_REPORT: [ROLES.ADMIN],
    ORDER_REPORT_FOR_WHOLESALE_PAYMENT: [ROLES.ADMIN, ROLES.FINANCE],
    TRACKING_LEADS_REPORT: [ROLES.ADMIN, ROLES.OP],
    TRACKING_REPORT: [ROLES.ADMIN],
    PROMOTION_LIST: [ROLES.ADMIN],
    SITEMAP_LIST: [ROLES.ADMIN],

    CUSTOMER_LIST: [ROLES.ADMIN, ROLES.TELESALES],
};
