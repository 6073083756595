import { HttpService } from './base/http.service';

import { Observable } from 'rxjs';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ENDPOINT } from '../constants/endpoint.constant';
import { OrderBalancePaginatedCriteria, OrderBalanceSummaryFilter } from '../models/order-balance.model';
import { TransferDetailPayload } from '../models/transfer-detail.model';
// import { OrderBalancePaginatedCriteria as OBPageCriteria } from '../models/order-balance-paginated.model';
import {
    PartnerOrderBalancePaginatedCriteria,
    PartnerOrderBalanceSummaryFilter,
} from '../models/partner-order-balance.model';
import {
    FeeReceiptOrderBalancePaginatedCriteria,
    FeeReceiptOrderBalanceSummaryFilter,
} from '../models/fee-receipt-order-balance.model';
import { PnOrderBalancePaginatedCriteria, PnOrderBalancePaginatedFilter } from '../models/pn-order-balance.model';
import { FeeReceiptPaginatedCriteria } from '../models/fee-receipt-paginated.model';
import { FeeReceiptSummaryFilter } from '../models/fee-receipt-summary.model';

@Injectable({
    providedIn: 'root',
})
export class OrderBalanceService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getAgencies(periodId: number): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.GET_AGENCIES;
        const params = {
            period_id: periodId,
        };

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPartners(periodId: number): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.PARTNER.GET_PARTNERS;
        const params = {
            period_id: periodId,
        };

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getFeeReceiptPartners(periodId: number): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.FEE_RECEIPT.GET_PARTNERS;
        const params = {
            period_id: periodId,
        };

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPeriods(): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.GET_PERIODS;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPartnerPeriods(): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.PARTNER.GET_PERIODS;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getOrderBalanceSummary(filters: OrderBalanceSummaryFilter): Observable<any> {
        let params = new HttpParams();
        let f: any = {};
        if (filters?.periodId) {
            f['period_id'] = filters?.periodId;
        }
        if (filters?.agencyId) {
            f['agency_id'] = filters?.agencyId;
        }

        if (JSON.stringify(filters) !== '{}') {
            params = params.append('filters', JSON.stringify(f));
        }

        const url = `${environment.apiUrl}${ENDPOINT.ORDER_BALANCE.GET_SUMMARY}`;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPartnerOrderBalanceSummary(filters: PartnerOrderBalanceSummaryFilter): Observable<any> {
        let params = new HttpParams();
        let f: any = {};
        if (filters?.periodId) {
            f['period_id'] = filters?.periodId;
        }
        if (filters?.partnerId) {
            f['partner_id'] = filters?.partnerId;
        }

        if (JSON.stringify(filters) !== '{}') {
            params = params.append('filters', JSON.stringify(f));
        }

        const url = `${environment.apiUrl}${ENDPOINT.ORDER_BALANCE.PARTNER.GET_SUMMARY}`;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getFeeReceiptOrderBalanceSummary(filters: FeeReceiptOrderBalanceSummaryFilter): Observable<any> {
        let params = new HttpParams();
        let f: any = {};
        if (filters?.periodId) {
            f['period_id'] = filters?.periodId;
        }
        if (filters?.partnerId) {
            f['partner_id'] = filters?.partnerId;
        }

        if (JSON.stringify(filters) !== '{}') {
            params = params.append('filters', JSON.stringify(f));
        }

        const url = `${environment.apiUrl}${ENDPOINT.ORDER_BALANCE.FEE_RECEIPT.GET_SUMMARY}`;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getFeeReceiptSummary(filters: FeeReceiptSummaryFilter): Observable<any> {
        let params = new HttpParams();
        let f: any = {};
        if (filters?.periodId) {
            f['period_id'] = filters?.periodId;
        }
        if (filters?.partnerId) {
            f['partner_id'] = filters?.partnerId;
        }

        if (JSON.stringify(filters) !== '{}') {
            params = params.append('filters', JSON.stringify(f));
        }

        const url = `${environment.apiUrl}${ENDPOINT.ORDER_BALANCE.FEE_RECEIPT.GET_FEE_RECEIPT_SUMMARY}`;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getOrderBalancePaginated(criteria: OrderBalancePaginatedCriteria): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.GET_PAGINATED;
        let params = new HttpParams();
        let f: any = {};
        if (criteria?.filters) {
            if (criteria?.filters?.agencyId) {
                f['agency_id'] = criteria?.filters?.agencyId;
            }
            if (criteria?.filters?.periodId) {
                f['period_id'] = criteria?.filters?.periodId;
            }
        }

        if (JSON.stringify(f) !== '{}') {
            params = params.append('filters', JSON.stringify(f));
        }
        if (criteria?.page) {
            params = params.append('page', criteria?.page);
        }
        if (criteria?.pageEntries) {
            params = params.append('page_entries', criteria?.pageEntries);
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPartnerOrderBalancePaginated(criteria: PartnerOrderBalancePaginatedCriteria): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.PARTNER.GET_PAGINATED;
        let params = new HttpParams();
        let f: any = {};
        if (criteria?.filters) {
            if (criteria?.filters?.partnerId) {
                f['partner_id'] = criteria?.filters?.partnerId;
            }
            if (criteria?.filters?.periodId) {
                f['period_id'] = criteria?.filters?.periodId;
            }
        }

        if (JSON.stringify(f) !== '{}') {
            params = params.append('filters', JSON.stringify(f));
        }
        if (criteria?.page) {
            params = params.append('page', criteria?.page);
        }
        if (criteria?.pageEntries) {
            params = params.append('page_entries', criteria?.pageEntries);
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getFeeReceiptOrderBalancePaginated(criteria: FeeReceiptOrderBalancePaginatedCriteria): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.FEE_RECEIPT.GET_PAGINATED;
        let params = new HttpParams();
        let f: any = {};
        if (criteria?.filters) {
            if (criteria?.filters?.partnerId) {
                f['partner_id'] = criteria?.filters?.partnerId;
            }
            if (criteria?.filters?.periodId) {
                f['period_id'] = criteria?.filters?.periodId;
            }
        }

        if (JSON.stringify(f) !== '{}') {
            params = params.append('filters', JSON.stringify(f));
        }
        if (criteria?.page) {
            params = params.append('page', criteria?.page);
        }
        if (criteria?.pageEntries) {
            params = params.append('page_entries', criteria?.pageEntries);
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getFeeReceiptPaginated(criteria: FeeReceiptPaginatedCriteria): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.FEE_RECEIPT.GET_FEE_RECEIPT_PAGINATED;
        let params = new HttpParams();
        let f: any = {};
        if (criteria?.filters) {
            if (criteria?.filters?.partnerId) {
                f['partner_id'] = criteria?.filters?.partnerId;
            }
            if (criteria?.filters?.periodId) {
                f['period_id'] = criteria?.filters?.periodId;
            }
        }

        if (JSON.stringify(f) !== '{}') {
            params = params.append('filters', JSON.stringify(f));
        }
        if (criteria?.page) {
            params = params.append('page', criteria?.page);
        }
        if (criteria?.pageEntries) {
            params = params.append('page_entries', criteria?.pageEntries);
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getFeeReceiptDoc(feeReceiptId: number): Observable<any> {
        const url = `${environment.apiUrl}${ENDPOINT.ORDER_BALANCE.FEE_RECEIPT.GET_FEE_RECEIPT_DOC}`.replace(
            '{fee_receipt_id}',
            feeReceiptId + ''
        );

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options);
            })
        );
    }

    public postConfirmFeeReceipt(feeReceiptId: number): Observable<any> {
        const url =
            environment.apiUrl +
            ENDPOINT.ORDER_BALANCE.FEE_RECEIPT.POST_CONFIRM_FEE_RECEIPT.replace('{fee_receipt_id}', feeReceiptId + '');

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.post(url, null, options);
            })
        );
    }

    public getOrderSummary(periodId: number, agencyId: number): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.GET_ORDER_SUMMARY;
        let params = new HttpParams();
        params = params.append(
            'filters',
            JSON.stringify({
                period_id: periodId,
                agency_id: agencyId,
            })
        );

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public putTransferDetail(balanceId: number, payload: TransferDetailPayload): Observable<any> {
        const url =
            environment.apiUrl + ENDPOINT.ORDER_BALANCE.PUT_TRANSFER_DETAIL.replace('{balance_id}', balanceId + '');
        let p: any = {};

        if (payload.transferedAt) {
            p['transfered_at'] = payload.transferedAt.toString();
        }
        if (payload.transferedAmount) {
            p['transfered_amount'] = payload.transferedAmount;
        }
        if (payload.transferDocBase64) {
            p['transfer_doc'] = {
                base64_data: payload.transferDocBase64,
                is_same_file: payload.isSameFile,
            };
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, p, options);
            })
        );
    }

    public getOrderPaginated(criteria: OrderBalancePaginatedCriteria): Observable<any> {
        const url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.GET_ORDER_PAGINATED;

        let params = new HttpParams();
        params = params.append(
            'filters',
            JSON.stringify({
                period_id: criteria.filters.periodId,
                agency_id: criteria.filters.agencyId,
            })
        );
        params = params.append('page', criteria.page + '');
        params = params.append('page_entries', criteria.pageEntries + '');
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPnPeriods(): Observable<any> {
        let url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.GET_PN_PERIODS;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPnPartners(periodId: number): Observable<any> {
        let url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.GET_PN_PARTNERS;

        const params = {
            period_id: periodId,
        };

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPnOrderBalanceSummary(filters: PnOrderBalancePaginatedFilter): Observable<any> {
        let url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.GET_PN_SUMMARY;
        let params = new HttpParams();
        let f: any = {};
        if (filters?.periodId) {
            f['period_id'] = filters?.periodId;
        }
        if (filters?.partnerId) {
            f['partner_id'] = filters?.partnerId;
        }

        if (JSON.stringify(filters) !== '{}') {
            params = params.append('filters', JSON.stringify(f));
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getPnOrderBalancePaginated(criteria: PnOrderBalancePaginatedCriteria): Observable<any> {
        let url = environment.apiUrl + ENDPOINT.ORDER_BALANCE.GET_PN_PAGINATED;
        let params = new HttpParams();
        let f: any = {};
        if (criteria?.filters) {
            if (criteria?.filters?.partnerId) {
                f['partner_id'] = criteria?.filters?.partnerId;
            }
            if (criteria?.filters?.periodId) {
                f['period_id'] = criteria?.filters?.periodId;
            }
        }

        if (JSON.stringify(f) !== '{}') {
            params = params.append('filters', JSON.stringify(f));
        }
        if (criteria?.page) {
            params = params.append('page', criteria?.page);
        }
        if (criteria?.pageEntries) {
            params = params.append('page_entries', criteria?.pageEntries);
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public putPnTransferDetail(balanceId: number, payload: TransferDetailPayload): Observable<any> {
        const url =
            environment.apiUrl + ENDPOINT.ORDER_BALANCE.PUT_PN_TRANSFER_DETAIL.replace('{balance_id}', balanceId + '');
        let p: any = {};

        if (payload.transferedAt) {
            p['transfered_at'] = payload.transferedAt.toString();
        }
        if (payload.transferedAmount) {
            p['transfered_amount'] = payload.transferedAmount;
        }
        if (payload.transferDocBase64) {
            p['transfer_doc'] = {
                base64_data: payload.transferDocBase64,
                is_same_file: payload.isSameFile,
            };
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, p, options);
            })
        );
    }
}
