export class OrderBalanceItemSummary implements IOrderBalanceItemSummary {
    public sumAmount!: number;
    public receiptItemQuantity!: number;
    public orderBalance!: IOrderBalance;

    public deserialize(resp: any): this {
        this.sumAmount = resp.sum_amount;
        this.receiptItemQuantity = resp.receipt_item_quantity;
        if (resp.order_balance) {
            this.orderBalance = new OrderBalance().deserialize(resp.order_balance);
        }
        return this;
    }
}

export interface IOrderBalanceItemSummary {
    sumAmount: number | null;
    receiptItemQuantity: number | null;
    orderBalance: IOrderBalance | null;
}

export class OrderBalance implements IOrderBalance {
    public id!: number;
    public sumNetCommissionAgency!: number;
    public transfered!: boolean;

    public deserialize(resp: any) {
        this.id = resp.id;
        this.sumNetCommissionAgency = resp.sum_net_commission_agency;
        this.transfered = resp.transfered;

        return this;
    }
}

export interface IOrderBalance {
    id: number | null;
    sumNetCommissionAgency: number | null;
    transfered: boolean | null;
}
