import { Country } from './country.model';
import { Province } from './province.model';

export class OrderPaginatedFilter {
    searchString?: string;
    supplierId?: number;
    agencyId?: number;
    orderCreateMinDate?: string | null;
    orderCreateMaxDate?: string | null;
}
export class OrderPaginatedCriteria {
    filters: OrderPaginatedFilter = new OrderPaginatedFilter();
    page: number = 1;
    pageEntries: number = 50;
}

export class OrderPaginated {
    page!: number | null;
    pageEntries!: number | null;
    total!: number | null;
    result!: OrderPaginatedItem[];

    public deserialize(source: any) {
        this.page = source.page;
        this.pageEntries = source.page_entries;
        this.total = source.total;
        this.result = source.result.map((r: any) => new OrderPaginatedItem().deserialize(r));

        return this;
    }
}

export class OrderExcelReportItem {
    id!: number;
    orderCode!: string;
    orderStatusSlug!: string;
    quantity!: number;
    sumAmount!: number;
    netSumAmount!: number;
    sumCommission!: number;
    sumCommissionCompany!: number;
    sumCommissionSeller!: number;
    fullyPaid!: boolean;
    createdAt!: Date;
    canceled!: boolean;
    canceledAt!: Date;
    cancelReason!: string;
    payment!: Payment;
    customer!: Customer;
    product!: Product;
    agency!: Agency;
    installments!: Installment[];
    productRooms!: ProductRoom[];

    deserialize(source: any): this {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.orderStatusSlug = source.order_status_slug;
        this.quantity = source.quantity;
        this.sumAmount = source.sum_amount;
        this.netSumAmount = source.net_sum_amount;
        this.sumCommission = source.sum_commission;
        this.sumCommissionCompany = source.sum_commission_company;
        this.sumCommissionSeller = source.sum_commission_seller;
        this.fullyPaid = source.fully_paid;
        this.createdAt = source.created_at;
        this.canceled = source.canceled;
        this.canceledAt = source.canceled_at;
        this.cancelReason = source.cancel_reason;
        this.payment = new Payment().deserialize(source.payment);
        this.customer = new Customer().deserialize(source.customer);
        this.product = new Product().deserialize(source.product);
        this.agency = new Agency().deserialize(source.agency);
        this.installments = source.installments.map((m: any) => new Installment().deserialize(m));
        this.productRooms = source.product_rooms.map((m: any) => new ProductRoom().deserialize(m));
        return this;
    }
}

export class OrderPaginatedItem {
    id!: number;
    orderCode!: string;
    orderStatusSlug!: string;
    quantity!: number;
    sumAmount!: number;
    netSumAmount!: number;
    sumCommission!: number;
    sumCommissionCompany!: number;
    sumCommissionSeller!: number;
    fullyPaid!: boolean;
    createdAt!: string;
    payment!: Payment;
    customer!: Customer;
    product!: Product;
    agency!: Agency;
    installments!: Installment[];

    deserialize(source: any): this {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.orderStatusSlug = source.order_status_slug;
        this.quantity = source.quantity;
        this.sumAmount = source.sum_amount;
        this.netSumAmount = source.net_sum_amount;
        this.sumCommission = source.sum_commission;
        this.sumCommissionCompany = source.sum_commission_company;
        this.sumCommissionSeller = source.sum_commission_seller;
        this.fullyPaid = source.fully_paid;
        this.createdAt = source.created_at;
        this.payment = new Payment().deserialize(source.payment);
        this.customer = new Customer().deserialize(source.customer);
        this.product = new Product().deserialize(source.product);
        this.agency = new Agency().deserialize(source.agency);
        this.installments = source.installments.map((m: any) => new Installment().deserialize(m));
        return this;
    }
}

export class ProductRoom {
    ordinal!: number;
    quantity!: number;
    amount!: number;
    roomType!: RoomType;

    deserialize(source: any): this {
        this.ordinal = source.ordinal;
        this.quantity = source.quantity;
        this.amount = source.amount;
        this.roomType = new RoomType().deserialize(source.room_type);
        return this;
    }
}

export class TransportationCategory {
    id!: number;
    nameTh!: string;
    slug!: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.nameTh = source.name_th;
        this.slug = source.slug;
        return this;
    }
}
export class Transportation {
    id!: number;
    code!: string;
    nameEn!: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.code = source.code;
        this.nameEn = source.name_en;
        return this;
    }
}
export class RoomType {
    slug!: string;
    nameTh!: string;

    deserialize(source: any): this {
        this.slug = source.slug;
        this.nameTh = source.name_th;
        return this;
    }
}

export class OmiseData {
    chargeId!: string;
    amount!: number;
    fee!: number;
    feeVat!: number;
    cardBrand!: string;
    cardType!: string;
    source!: string;
    transactionType!: string;
    transactionAmount!: number;
    transactionCreatedAt!: string;
    transactionTransferableAt!: string;

    deserialize(source: any): this {
        this.chargeId = source.charge_id;
        this.amount = source.amount;
        this.fee = source.fee;
        this.feeVat = source.fee_vat;
        this.cardBrand = source.card_brand;
        this.cardType = source.card_type;
        this.source = source.source;
        this.transactionType = source.transaction_type;
        this.transactionAmount = source.transaction_amount;
        this.transactionCreatedAt = source.transaction_created_at;
        this.transactionTransferableAt = source.transaction_transferable_at;
        return this;
    }
}

export class Payment {
    originalAmount!: number;
    amount!: number;
    twFee!: number;
    omiseData!: OmiseData;

    deserialize(source: any): this {
        this.originalAmount = source.original_amount;
        this.amount = source.amount;
        this.twFee = source.tw_fee;
        this.omiseData = new OmiseData().deserialize(source.omise_data);
        return this;
    }
}

export class Customer {
    contactName!: string;
    phoneNumber!: string;
    email!: string;
    customerRemark!: string;

    deserialize(source: any): this {
        this.contactName = source.contact_name;
        this.phoneNumber = source.phone_number;
        this.email = source.email;
        this.customerRemark = source.customer_remark;
        return this;
    }
}

export class SubCategory {
    id!: number;
    slug!: string;
    name!: string;
    nameTh!: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.slug = source.slug;
        this.name = source.name;
        this.nameTh = source.name_th;
        return this;
    }
}

export class Period {
    startAt!: string;
    endAt!: string;

    deserialize(source: any): this {
        this.startAt = source.start_at;
        this.endAt = source.end_at;
        return this;
    }
}

export class Owner {
    id!: number;
    channel!: string;
    nameTh!: string;
    nameEn!: string;
    phoneNumber!: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.channel = source.channel;
        this.nameTh = source.name_th;
        this.nameEn = source.name_en;
        this.phoneNumber = source.phone_number;
        return this;
    }
}

export class Product {
    name!: string;
    tourCode!: string;
    commissionSeller!: number;
    commissionCompany!: number;
    subCategory!: SubCategory;
    period!: Period;
    owner!: Owner;
    countries!: Country[];
    provinces!: Province[];
    transportationCategory!: TransportationCategory;
    transportation!: Transportation;

    deserialize(source: any): this {
        this.name = source.name;
        this.tourCode = source.tour_code;
        this.commissionSeller = source.commission_seller;
        this.commissionCompany = source.commission_company;
        this.subCategory = new SubCategory().deserialize(source.sub_category);
        this.period = new Period().deserialize(source.period);
        this.owner = new Owner().deserialize(source.owner);
        if (source.countries) {
            this.countries = source.countries.map((m: any) => new Country().deserialize(m));
        }
        if (source.provinces) {
            this.provinces = source.provinces.map((m: any) => new Province().deserialize(m));
        }
        if (source.transportation_category) {
            this.transportationCategory = new TransportationCategory().deserialize(source.transportation_category);
        }
        if (source.transportation) {
            this.transportation = new Transportation().deserialize(source.transportation);
        }
        return this;
    }
}

export class Agency {
    id!: number;
    nameTh!: string;
    nameEn!: string;
    phoneNumber!: string;
    productCode!: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.nameTh = source.name_th;
        this.nameEn = source.name_en;
        this.phoneNumber = source.phone_number;
        this.productCode = source.product_code;
        return this;
    }
}

export class Installment {
    amount!: number;
    paidAt!: string;

    deserialize(source: any): this {
        this.amount = source.amount;
        this.paidAt = source.paid_at;
        return this;
    }
}
