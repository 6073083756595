<section id="login-section">
    <div class="bg"></div>
    <form class="form-login" [formGroup]="loginForm" >
        <div class="row form-group">
            <div class="col-3">
                <img id="tourwow-logo" src="/assets/images/tourwow-logo.png" alt="Tourwow Logo" />
            </div>

            <!-- login section -->
            <div class="col-3" *ngIf="!loginSuccessful">
                <label for="usernameInput">Username</label>
                <input [ngClass]="{ 'red-highlight': isLoginSubmitted && !loginSuccessful }" formControlName="username" type="text" class="form-control" id="usernameInput" required />
                <small *ngIf="isLoginSubmitted && !loginSuccessful" class="form-text text-danger">Username ไม่ถูกต้อง</small>
            </div>
            <div class="col-3" *ngIf="!loginSuccessful">
                <label for="passwordInput">Password</label>
                <input [ngClass]="{ 'red-highlight': isLoginSubmitted && !loginSuccessful }" formControlName="password" type="password" class="form-control" id="passwordInput" required />
                <small *ngIf="isLoginSubmitted && !loginSuccessful" class="form-text text-danger">Password ไม่ถูกต้อง</small>
            </div>
            <div class="col-3" *ngIf="!loginSuccessful">
                <button (click)="login()" id="login-btn" type="button" class="btn btn-primary btn-lg">
                    เข้าสู่ระบบ
                </button>
            </div>
            <!-- login section -->

            <!-- otp section -->
            <div class="col-3" *ngIf="loginSuccessful">
                <label>ข้อมูลบัญชี</label>
                <p style="margin-top: 0.7rem;">{{ firstName }} {{ lastName }} ({{ maskedMobileNumber }})</p>
            </div>
            <div class="col-2" *ngIf="loginSuccessful">
                <label for="otpCode">OTP</label>
                <input [ngClass]="{ 'red-highlight': isVerifyOtpSubmitted && !verifyOtpSuccessful }" formControlName="otpCode" type="text" class="form-control" id="otpCode" required />
                <small *ngIf="isVerifyOtpSubmitted && !verifyOtpSuccessful" class="form-text text-danger">
                    <ng-container *ngIf="isSendOtpSubmitted"> 
                        OTP ไม่ถูกต้องหรือหมดอายุไปแล้ว 
                    </ng-container>
                    <ng-container *ngIf="!isSendOtpSubmitted"> 
                        กรุณาส่ง OTP ก่อน 
                    </ng-container>
                </small>                
            </div>
            <div class="col-auto d-flex px-0" *ngIf="loginSuccessful">
                <button *ngIf="sendOtpSecondsToWait === 0" (click)="sendOtp()" id="send-otp-btn" class="btn btn-link">
                    <label>ส่ง OTP</label>
                </button>
                <div *ngIf="sendOtpSecondsToWait > 0" id="send-otp-text" class="btn btn-link">
                    <label>ส่ง OTP อีกครั้ง({{ sendOtpSecondsToWait }} วินาที)</label>
                </div>
                <button (click)="verifyOTP()" id="verify-otp-btn" [disabled]="f?.otpCode?.errors?.required" type="button" class="btn btn-warning ml-2">
                    ยืนยัน
                </button>
                <button (click)="refreshPage()" id="send-otp-btn" class="btn btn-link ml-2">
                    <label>ยกเลิก</label>
                </button>
            </div>
            <!-- otp section -->

        </div>
    </form>
</section>
