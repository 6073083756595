import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Agency } from 'src/app/models/agency.model';
import { AgencyService } from 'src/app/services/agency.service';
import * as AgencyActions from './agency.actions';

@Injectable()
export class AgencyEffects {
    constructor(
        private actions$: Actions,
        private ngxUiLoaderService: NgxUiLoaderService,
        private agencyService: AgencyService
    ) {}

    public getAgencies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AgencyActions.getAgencies),
            switchMap((action) => {
                this.ngxUiLoaderService.start();
                return this.agencyService.getAgencies().pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status == 'success') {
                            return AgencyActions.getAgenciesSuccess({
                                data: resp.data.map((d: any) => new Agency().deserialize(d)),
                            });
                        } else {
                            return AgencyActions.getAgenciesFailure({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(
                            AgencyActions.getAgenciesFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );
}
