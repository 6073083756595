import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { MainThemeComponent } from './themes/main-theme/main-theme.component';

//
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

//
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './stores/reducers';
import { OrderBalanceEffects } from './stores/order-balance/order-balance.effects';
import { AgencyEffects } from './stores/agency/agency.effects';
import { PartnerEffects } from './stores/partner/partner.effects';
import { CampaignEffects } from './stores/campaign/campaign.effects';
import { defineLocale, thBeLocale } from 'ngx-bootstrap/chronos';
import { SupplierEffects } from './stores/supplier/supplier.effects';
import { OrderEffects } from './stores/order/order.effects';
import { FormBuilder } from '@angular/forms';
import { BookingEffects } from './stores/booking/booking.effects';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@NgModule({
    declarations: [AppComponent, MainThemeComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxUiLoaderModule,

        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        CollapseModule.forRoot(),

        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production , connectInZone: true}),
        EffectsModule.forRoot([
            OrderBalanceEffects,
            AgencyEffects,
            PartnerEffects,
            CampaignEffects,
            SupplierEffects,
            OrderEffects,
            BookingEffects,
        ]),

        FontAwesomeModule,
    ],
    providers: [FormBuilder],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(localeService: BsLocaleService) {
        defineLocale('th-be', thBeLocale);
        localeService.use('th-be');
    }
}
