
export class Province {
    id!: number;
    nameTh!: string;
    nameEn!: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.nameTh = source.name_th;
        this.nameEn = source.name_en;
        return this;
    }
}