export const ENDPOINT = {
    SUPPLIER: {
        GET_ALL: 'suppliers',
    },
    SUPPLIER_CONFIG: {
        GET_ONE: 'supplier_config',
    },
    AGENCY: {
        GET_ALL: 'agencies',
    },
    PARTNER: {
        GET_ALL: 'partners',
    },
    CAMPAIGN: {
        PAYMENT: {
            GET: 'campaigns/payments',
            GET_COUNT: 'campaigns/payments/count',
            GET_PAGINATED: 'campaigns/payments/paginated',
        },
        ORDER: {
            CANCEL: 'campaigns/orders/{order_id}/cancel',
        },
    },
    CUSTOMER: {
        GET_CUSTOMERS: 'tw_customers/paginated',
        GET_CUSTOMER_DETAIL: 'tw_customers/{customer_code}',
    },
    PRODUCT: {
        POOL: {
            GET_PERIODS: 'tw_product_pools/periods/paginated',
            COUNTRIES: 'tw_product_pools/countries',
            COUNTRY_SUB_UNITS: 'tw_product_pools/country_sub_units',
            TAGS: 'tw_product_pools/tags',
            FILE: 'tw_product_pools/file',
            CONFIG: {
                POST_FILE: 'tw_product_pool_config_files',
                POST_BY_POOL:
                    'tw_product_pool_configs/suppliers/{supplier_id}/products/{tour_code}/periods/{start_at}_{end_at}',
                PUT_BY_POOL:
                    'tw_product_pool_configs/suppliers/{supplier_id}/products/{tour_code}/periods/{start_at}_{end_at}',
                GET_PAGINATED: 'tw_product_pool_configs/paginated',
                PUT_BY_ID: 'tw_product_pool_configs/{id}',
                DELETE_BY_ID: 'tw_product_pool_configs/{id}',
                COUNTRIES: 'tw_product_pool_configs/countries',
                COUNTRY_SUB_UNITS: 'tw_product_pool_configs/country_sub_units',
                SUPPLIERS: 'tw_product_pool_configs/suppliers',
            },
        },
        ORDER: {
            GET_PAGINATED: 'products/orders/paginated',
            GET_EXCEL_REPORT: 'products/orders/excel_reports',
        },
    },
    PIPELINE: {
        GET_PIPELINE_TYPE: 'tw_pipeline_types',
        GET_DATE_CONFIG: 'tw_touchpoint_date_configs',
        UPLOAD_BANNER_IMG: 'tw_pipeline_files',
        PIPELINE_TEMPLATES: 'tw_pipeline_templates',
        GET_PIPELINE_TEMPLATES_NAMES: 'tw_pipeline_templates/names',
        GET_PIPELINE_TEMPLATES: 'tw_pipeline_templates/paginated',
        GET_PIPELINE_NAMES: 'tw_pipelines/names',
        BUDDY_AGENCY_MEMBERS: 'tw_pipelines/buddy_agency_members',
        BUDDY_AGENCY_MEMBER: 'tw_pipelines/buddy_agency_member',
        CHECK_AVALIABLE: 'tw_pipelines/check_availability',
    },
    ORDER_BALANCE: {
        GET_AGENCIES: 'agcy/order_balances/agencies',
        GET_PERIODS: 'agcy/order_balances/periods',
        GET_SUMMARY: 'agcy/order_balances/summary',
        GET_PAGINATED: 'agcy/order_balances/paginated',
        PUT_TRANSFER_DETAIL: 'agcy/order_balances/{balance_id}/transfer_detail',
        GET_ORDER_SUMMARY: 'agcy/order_balances/items/summary',
        GET_ORDER_PAGINATED: 'agcy/order_balances/items/paginated',
        FEE_RECEIPT: {
            GET_PARTNERS: 'agcy/order_balances/fee_receipts/partners',
            GET_SUMMARY: 'agcy/order_balances/fee_receipts/items/summary',
            GET_PAGINATED: 'agcy/order_balances/fee_receipts/items/paginated',
            GET_FEE_RECEIPT_SUMMARY: 'agcy/order_balances/fee_receipts/summary',
            GET_FEE_RECEIPT_PAGINATED: 'agcy/order_balances/fee_receipts/paginated',
            GET_FEE_RECEIPT_DOC: 'agcy/order_balances/fee_receipts/{fee_receipt_id}/receipt_doc',
            POST_CONFIRM_FEE_RECEIPT: 'agcy/order_balances/fee_receipts/{fee_receipt_id}/confirm',
        },
        PARTNER: {
            GET_PARTNERS: 'pn/order_balances/partners',
            GET_PERIODS: 'pn/order_balances/periods',
            GET_SUMMARY: 'pn/order_balances/items/summary',
            GET_PAGINATED: 'pn/order_balances/items/paginated',
        },
        GET_PN_PARTNERS: 'pn/order_balances/partners',
        GET_PN_PERIODS: 'pn/order_balances/periods',
        GET_PN_SUMMARY: 'pn/order_balances/summary',
        GET_PN_PAGINATED: 'pn/order_balances/paginated',
        PUT_PN_TRANSFER_DETAIL: 'pn/order_balances/{balance_id}/transfer_detail',
    },
    TOURWOW_ORDER: {
        NEW_ORDER: 'tw_orders',
        GET_ORDER: 'tw_orders/{order_code}',
        UPDATE_ORDER: 'tw_orders/{order_code}',
        UPDATE_ORDER_CUSTOMER_INFO: '/tw_orders/{order_code}/customer_info',
        UPDATE_ORDER_SUPPLIER_INSTALLMENT: '/tw_orders/{order_code}/supplier_order_installments',
        GET_LIST_COMMISSION_PAGINATED: 'tw_orders/commission/paginated',
        GET_LIST_COMMISSION_FILE: 'tw_orders/commission/file',
        GET_LIST_PAGINATED: 'tw_orders/paginated',
        UPDATE_STATUS: 'tw_orders/{order_code}/status',
        WAITING_CANCELED_STATUS: 'tw_orders/{order_code}/waiting_canceled',
        CANCELED_STATUS: 'tw_orders/{order_code}/canceled',
        UPDATE_PIN: 'tw_orders/{order_code}/pin',
        GET_NOTE: 'tw_orders/{order_code}/note',
        UPDATE_NOTE: 'tw_orders/{order_code}/note',
        UPLOAD_INVOICE_FILE: 'tw_orders/{order_code}/files',
        DELETE_INVOICE_FILE: 'tw_orders/{order_code}/files/{order_file_id}',
        UPLOAD_SUPPLIER_INSTALLMENT_FILE:
            'tw_orders/{order_code}/supplier_order_installments/{supplier_order_installment_id}/files',
        DELETE_SUPPLIER_INSTALLMENT_FILE:
            'tw_orders/{order_code}/supplier_order_installments/{supplier_order_installment_id}/files/{supplier_order_installment_file_id}',
        UPLOAD_CUSTOMER_INSTALLMENT_FILE:
            'tw_orders/{order_code}/customer_order_installments/{customer_order_installment_id}/files',
        DELETE_CUSTOMER_INSTALLMENT_FILE:
            'tw_orders/{order_code}/customer_order_installments/{customer_order_installment_id}/files/{customer_order_installment_file_id}',
        GET_PAID_CUSTOMER_INSTALLMENTS: 'tw_orders/customer_installments/paid_payment',
        PUT_PAID_CUSTOMER_INSTALLMENT: 'tw_orders/customer_installments/paid_payment',
        GET_PDF: 'tw_orders/{order_code}/receipt',

        GET_AGENCY_MEMBER_TEAM: 'tw_orders/team_seller_agency_members',
        GET_AGENCY_MEMBER: 'tw_orders/seller_agency_members',

        DELETE_ORDER_PERIOD_GENERATION_REPORT: 'tw_orders/order_period_generation_reports/{id}',
        POST_ORDER_PERIOD_GENERATION_REPORT_FILE: 'tw_orders/order_period_generation_report_files',
        GET_ORDER_PERIOD_GENERATION_REPORT_PAGINATED: 'tw_orders/order_period_generation_reports/paginated',
        GET_ORDER_GENERATE_ORDER_SUPPLIER_EXCEL_AS_BASE64: 'tw_orders/generate_order_supplier_excel_as_base64',

        GET_ORDER_GENERATE_ORDER_TEAM_REPORT: '/tw_orders/generate_order_seller_agency_member_team_report',
        UPDATE_DOCUMENT: 'tw_orders/{order_code}/order_document_info',
        APPROVE_PASSPORT: 'tw_orders/{order_code}/approve_passport',
        APPROVE_VISA: 'tw_orders/{order_code}/approve_visa',
    },
    TOURWOW_SUPPLIER_ORDER_INSTALLMENT: {
        GET_SUPPLIER_ORDER_INSTALLMENT_FILE_LIST_PAGINATED: 'tw_supplier_order_installments/file/paginated',
        GET_SUPPLIER_ORDER_INSTALLMENT_FILE: 'tw_supplier_order_installments/file',
    },
    AUTH: {
        LOGIN: 'auth/login',
        POST_SEND_OTP: 'auth/send-otp',
        POST_VERIFY_OTP: 'auth/verify-otp',
    },
    LOCATION: {
        GET_COUNTRY_LIST: 'countries',
        GET_COUNTRY_SUB_UNIT_LIST: 'country_sub_units',
    },
    KEYWORD: {
        GET_KEYWORD_PAGINATED: 'keywords/paginated',
        GET_KEYWORD: 'keywords/{keyword_id}',
        GET_KEYWORD_BY_DISPLAY_NAME: 'keywords',
        ADD_KEYWORD: 'keywords',
        UPDATE_KEYWORD: 'keywords/{keyword_id}',
        UPLOAD_IMAGE: 'keywords/image_files',
        CHECK_DISPLAY_NAME: 'keywords/display_name/check_availability',
    },
    TAG: {
        GET_TAG_PAGINATED: 'tw_tags/paginated',
        DELETED_TAG: 'tw_tags/{tag_id}',
    },
    WORK_LIST: {
        GET: 'tw_orders/{order_code}/tasks',
        PUT: 'tw_orders/{order_code}/tasks',
        UPDATE_STATUS: 'tw_orders/tasks/{task_id}/status',
        GET_TASK_PAGINATED: 'tw_orders/tasks/paginated',
        GET_SELLER: 'tw_orders/seller_agency_members',
        GET_TASK_TYPES: '/tw_orders/task_types',
    },

    AIRLINE: {
        GET: '/transportations',
    },
    AIRPORT: {
        GET: 'airports',
    },
    BANK: {
        GET: 'banks',
    },
    BOOKING: {
        GET_BOOKING: 'tw_bookings/{booking_code}',
        GET_BOOKING_PAGINATED: 'tw_bookings/paginated',
        GET_BOOKING_TEAM_NUMBERS: 'tw_booking_channels/team_numbers',
        GET_MEMBERS_CREATE_BOOKING: 'tw_bookings/created_by_agency_members',
        GET_MEMBERS_TRACKING_BOOKING: 'tw_booking_status_trackings/seller_by_agency_members',
        GET_MEMBERS_TRACKING_SELLER_TEAMS: 'tw_booking_status_trackings/seller_by_agency_member_teams',
        GET_CREATE_BY_BOOKING_CHANNELS: 'tw_bookings/created_by_channels',
        GET_BOOKING_CHANNELS: 'tw_bookings/booking_channels',
        GET_BOOKING_COUNTRIES: 'tw_bookings/countries',
        GET_PRIORITY_STATUS: 'tw_bookings/booking_priority_statuses',
        PUT_HAS_MATCHING_LAST_VIEW: 'tw_bookings/{booking_code}/verify_last_viewed_by_agency_member',
        UPDATE_LAST_VIEW: 'tw_bookings/{booking_code}/last_viewed_by_agency_member',
        GET_CREATED_AGENCY_MEMBER_TEAMS: 'tw_bookings/created_by_agency_member_teams',
        // Queue
        QUEUE_CHECKIN: 'tw_bookings/queue/checkin',
        QUEUE_CHECKOUT: 'tw_bookings/queue/checkout',
        QUEUE_REVOKE: 'tw_bookings/queue/revoke',
        QUEUE_GET_ACTIVATED_MEMBER: 'tw_bookings/queue/active',
        QUEUE_CHECK_STATUS: 'tw_bookings/queue/status',
        //
        GET_TASK_TRACKING_REPORT: 'tw_booking_status_trackings/task_tracking_report',
        GET_SELL_TRACKING_REPORT: 'tw_booking_status_trackings/sell_tracking_report',
        //
        GET_BOOKING_COUNT_REPORT: 'tw_bookings/booking_count_report_paginated',
        GET_BOOKING_TELESALES_PERFORMANCE_REPORT: 'tw_bookings/telesales_performance_report_paginated',
        //
        GET_BOOKING_CONFIGS: 'v2/tw_booking_configs',
        GET_BOOKING_PAGINATED_V2: 'v2/tw_bookings/paginated',
        GET_TASK_TRACKING_REPORT_V2: 'v2/tw_booking_status_trackings/task_tracking_report',
        GET_SELL_TRACKING_REPORT_V2: 'v2/tw_booking_status_trackings/sell_tracking_report',
        GET_CUSTOMER_PRIORITY_STATUS: 'v2/tw_bookings/customer_priority_statuses',

        GET_SUMMARY_BOOKING_REPORT: 'tw_orders/generate_order_seller_agency_member_team_summary_report',
    },
    PROMOTION: {
        GET_PROMOTION_LIST: 'promotion_page_configs/paginated',
        POST_PROMOTION_PAGE_CONFIG_FILE: 'promotion_page_config_files',
        PROMOTION_PAGE_CONFIG: 'promotion_page_configs',
    },
    SITEMAP: {
        POST_SITEMAP: 'tw_sitemaps',
        DELETE_SITEMAP: 'tw_sitemaps/{id}',
        GET_SITEMAP_PAGINATED: 'tw_sitemaps/paginated',
    },
};
