// export class OrderBalancePaginatedCriteria {
//     periodId?: number | null;
//     agencyId?: number | null;
//     page?: number;
//     pageEntries?: number;
// }

export class OrderBalancePaginated implements IOrderBalancePaginated {
    page!: number | null;
    pageEntries!: number | null;
    total!: number | null;
    result!: IOrderBalancePaginatedItem[];

    public deserialize(source: any): IOrderBalancePaginated {
        this.page = source.page;
        this.pageEntries = source.page_entries;
        this.total = source.total;
        this.result = source.result.map((r: any) => new OrderBalancePaginatedItem().deserialize(r));

        return this;
    }
}

export interface IOrderBalancePaginated {
    page: number | null;
    pageEntries: number | null;
    total: number | null;
    result: IOrderBalancePaginatedItem[] | null;
}

export class OrderBalancePaginatedItem implements IOrderBalancePaginatedItem {
    id!: number;
    itemQuantity!: number;
    amount!: number;
    commissionAgency!: number;
    commissionAgencyVAT!: number;
    commissionAgencyWHTax!: number;
    netCommissionAgency!: number;
    order!: IOrder;
    campaign!: ICampaign;
    voucher!: IVoucher;
    productOwnerPartner!: IProductOwnerPartner;

    public deserialize(source: any): IOrderBalancePaginatedItem {
        this.id = source.id;
        this.itemQuantity = source.item_quantity;
        this.amount = source.amount;
        this.commissionAgency = source.commission_agency;
        this.commissionAgencyVAT = source.commission_agency_vat;
        this.commissionAgencyWHTax = source.commission_agency_wh_tax;
        this.netCommissionAgency = source.net_commission_agency;

        this.order = new Order().deserialize(source.order);
        this.campaign = new Campaign().deserialize(source.order);
        this.voucher = new Voucher().deserialize(source.voucher);
        this.productOwnerPartner = new ProductOwnerPartner().deserialize(source.product_owner_partner);

        return this;
    }
}

export interface IOrderBalancePaginatedItem {
    id: number;
    itemQuantity: number;
    amount: number;
    commissionAgency: number;
    commissionAgencyVAT: number;
    commissionAgencyWHTax: number;
    netCommissionAgency: number;
    order: IOrder;
    campaign: ICampaign;
    voucher: IVoucher;
    productOwnerPartner: IProductOwnerPartner;
}

export class Order implements IOrder {
    public id!: number;
    public orderCode!: string;
    public contactName!: string;
    public phoneNumber!: string;
    public paidAt!: Date | null;

    public deserialize(source: any): IOrder {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.contactName = source.contact_name;
        this.phoneNumber = source.phone_number;
        this.paidAt = source.paid_at ? new Date(source.paid_at) : null;
        return this;
    }
}

export interface IOrder {
    id: number | null;
    orderCode: string | null;
    contactName: string | null;
    phoneNumber: string | null;
    paidAt: Date | null;
}

export class Campaign implements ICampaign {
    public id!: number | null;
    public shopName!: string | null;
    public officeTelephoneNumber!: string | null;

    public deserialize(source: any): ICampaign {
        this.id = source.id;
        this.shopName = source.shop_name;
        this.officeTelephoneNumber = source.office_telephone_number;
        return this;
    }
}

export interface ICampaign {
    id: number | null;
    shopName: string | null;
    officeTelephoneNumber: string | null;
}

export class Voucher implements IVoucher {
    public id!: number;
    public name!: string;
    public price!: number;
    public commissionAgency!: number;

    public deserialize(source: any): IVoucher {
        this.id = source.id;
        this.name = source.name;
        this.price = source.price;
        this.commissionAgency = source.commission_agency;

        return this;
    }
}

export interface IVoucher {
    id: number | null;
    name: string | null;
    price: number | null;
    commissionAgency: number | null;
}

export class ProductOwnerPartner implements IProductOwnerPartner {
    public id!: number;
    public nameTH!: string;
    public nameEN!: string;
    public officeTelephoneNumber!: string;
    public additionalPhoneNumber!: string;
    public email!: string;
    public address!: string;

    public deserialize(source: any): IProductOwnerPartner {
        this.id = source.id;
        this.nameTH = source.name_th;
        this.nameEN = source.name_en;
        this.officeTelephoneNumber = source.office_telephone_number;
        this.additionalPhoneNumber = source.additional_phone_number;
        this.email = source.email;
        this.address = source.address;

        return this;
    }
}

export interface IProductOwnerPartner {
    id: number | null;
    nameTH: string | null;
    nameEN: string | null;
    officeTelephoneNumber: string | null;
    additionalPhoneNumber: string | null;
    email: string | null;
    address: string | null;
}
