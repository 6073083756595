import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

import { LoginPageComponent } from './modules/shared/pages/login-page/login-page.component';
import { PageForbiddenComponent } from './modules/shared/pages/page-forbidden/page-forbidden.component';
import { PageNotFoundComponent } from './modules/shared/pages/page-not-found/page-not-found.component';
import { LogoutPageComponent } from './modules/shared/pages/logout-page/logout-page.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginPageComponent,
        pathMatch: 'full',
    },
    {
        path: 'logout',
        component: LogoutPageComponent,
        pathMatch: 'full',
    },
    {
        path: 'forbidden',
        component: PageForbiddenComponent,
        pathMatch: 'full',
    },
    {
        path: 'order-balance',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/order-balance/order-balance.module').then((m) => m.OrderBalanceModule),
    },
    {
        path: 'tw-booking',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./modules/tw-order-balance/tw-order-balance.module').then((m) => m.TwOrderBalanceModule),
    },
    {
        path: 'v2/tw-booking',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/tw-booking/tw-booking.module').then((m) => m.TwBookingModule),
    },
    {
        path: 'product',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule),
    },
    {
        path: 'sale-support',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/sale-support/sale-support.module').then((m) => m.SaleSupportModule),
    },
    {
        path: 'commission-report',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./modules/commission-report/commission-report.module').then((m) => m.CommissionReportModule),
    },
    {
        path: 'cash-outflow-report',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./modules/cash-outflow-report/cash-outflow-report.module').then((m) => m.CashOutflowReportModule),
    },
    {
        path: 'report',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/report/report.module').then((m) => m.ReportModule),
    },
    {
        path: 'order',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
    },
    {
        path: 'customer',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/customer/customer.module').then((m) => m.CustomerModule),
    },
    {
        path: 'customers',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/customers/customers.module').then((m) => m.CustomerModule),
    },
    {
        path: 'booking',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/booking/booking.module').then((m) => m.BookingModule),
    },
    {
        path: 'leads',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/leads/leads.module').then((m) => m.LeadsModule),
    },
    {
        path: 'config',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/page-config/page-config.module').then((m) => m.PageConfigModule),
    },
    {
        path: 'sitemap',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/sitemap/sitemap.module').then((m) => m.SitemapModule),
    },
    {
        path: 'pipeline',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/pipeline/pipeline.module').then((m) => m.PipelineModule),
    },
    {
        path: 'gallery',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/gallery/gallery.module').then((m) => m.GalleryModule),
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
