import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as BookingActions from './booking.actions';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BookingService } from 'src/app/modules/booking/services/booking.service';

@Injectable()
export class BookingEffects {
    constructor(
        private actions$: Actions,
        private ngxUiLoaderService: NgxUiLoaderService,
        private bookingService: BookingService
    ) {}

    getBookingConfigs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingConfigs),
            switchMap((action) => {
                return this.bookingService.getBookingConfigs().pipe(
                    map((resp) => {
                        if (resp.status == 'success') {
                            return BookingActions.getBookingConfigsSuccess({
                                data: resp.data,
                            });
                        } else {
                            return BookingActions.getBookingConfigsFailure({
                                error: resp.message,
                            });
                        }
                    }),
                    catchError((error) => {
                        return of(
                            BookingActions.getBookingConfigsFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );
}
